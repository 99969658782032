import React, { useContext, Dispatch, useEffect, useState } from 'react'
import {
  Badge,
  Icon,
  Popover,
  FilterType,
  FileUploader,
  Column
} from '@jdlt-ltd/pongo'
import { OptionsButton } from '../../../components/OptionsButton'
import { FileOrFolder } from '../../types'
import { UserContext } from '../../userContext'
import { getFileOrFolderDropdown } from '../../getFileOrFolderDropdown'
import { Link } from 'react-router-dom'
import { useStartUpload } from '../useUploadFile'
import {
  getFormattedDate,
  getFormattedFileSize,
  encodeUrlPath
} from '../../../utils'
import { S3ObjectsData } from '../../../services/graphQl/s3'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import {
  getFileExtension,
  mediaPlayerFileExtensionList
} from '../../fileAndFolderFuncs'

export const useGetColumns = ({
  handleClickRename,
  handleDelete,
  handleDownload,
  handleClickMove,
  handleView,
  s3Data,
  setS3Data,
  filesWithActionInProgress
}: {
  handleClickRename: Function
  handleDelete: Function
  handleDownload: Function
  handleClickMove: Function
  handleView: Function
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>
  filesWithActionInProgress: string[]
}): { columns: Column[] } => {
  const { currentFolderPath, activeClient } = useContext(UserContext)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const startUpload = useStartUpload({ s3Data, setS3Data })

  const columns = [
    {
      key: `name`,
      header: `name`,
      filterType: `text` as FilterType,
      renderAsCell: ({
        name,
        iconName,
        iconCssClasses,
        newFile,
        type
      }: FileOrFolder): React.ReactNode => {
        const nameComponent = (
          <>
            <Icon
              iconName={iconName}
              cssClasses={[`h-5`, `w-5`, `mr-2`, `inline`, ...iconCssClasses]}
            />
            <strong className="hover:text-blue-600">{name}</strong>
            {newFile && (
              <Badge
                title="New"
                color="green"
                cssClasses={['ml-4', 'inline-flex', 'items-center']}
                dot
              />
            )}
          </>
        )

        return type === 'folder' ? (
          <FileUploader
            name={name}
            onFilesAccepted={(filesList) =>
              startUpload([...filesList], false, name)
            }
            dropAreaCssClasses={['-m-3', 'p-3']}
          >
            <Link
              to={encodeUrlPath(
                `/docs/${activeClient?.id}/${
                  currentFolderPath ? `${currentFolderPath}/` : ``
                }${name}`
              )}
            >
              {nameComponent}
            </Link>
          </FileUploader>
        ) : (
          <span
            onClick={() => handleView({ value: name })}
            style={{ cursor: `pointer` }}
          >
            {nameComponent}
          </span>
        )
      }
    },
    {
      key: `lastModified`,
      header: `last modified`,
      filterType: `date` as FilterType,
      renderAsCell: ({ lastModified }: FileOrFolder): React.ReactNode => {
        if (lastModified) {
          const lastModifiedWords = formatDistanceToNow(new Date(lastModified))
          return (
            <Popover
              referenceElement={
                <>
                  <time dateTime={lastModified}>
                    {`${lastModifiedWords
                      .charAt(0)
                      .toUpperCase()}${lastModifiedWords.slice(1)} ago`}
                  </time>
                </>
              }
              popperElement={
                <div className="p-1">
                  <div className="rounded-lg shadow-lg">
                    <div className="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative px-2 py-1 text-xs bg-white">
                        {getFormattedDate(lastModified)}
                      </div>
                    </div>
                  </div>
                </div>
              }
              triggerEvent={windowWidth >= 640 ? 'mouseOver' : 'click'}
              placement="top"
              closeOnClickOutside
              closeOnEsc
            />
          )
        }
        return null
      },
      cssClasses: ['hidden', 'sm:table-cell']
    },
    // {
    //   key: `updatedBy`,
    //   header: `updated by`,
    //   filterType: `select` as FilterType,
    //   options: [
    //     `Jake Dowie`,
    //     `Tommy Long`,
    //     `Rachel Dolan`,
    //     `Richard Tzanov`,
    //     `Richard Turner`,
    //     `Francisco Dias`
    //   ],
    //   cssClasses: ['hidden', 'md:table-cell']
    // },
    {
      key: `size`,
      header: `file size`,
      filterType: `number` as FilterType,
      renderAsCell: ({ size }: FileOrFolder): React.ReactNode =>
        size ? getFormattedFileSize(size) : null,
      cssClasses: ['hidden', 'lg:table-cell']
    },
    {
      key: `options`,
      header: ` `,
      renderAsCell: (fileOrFolder: FileOrFolder): React.ReactNode => {
        const { name, path } = fileOrFolder

        const actionInProgress = filesWithActionInProgress.find(
          (file) => file === `${path}/${name}`
        )

        const isCompatibleWithViwer = mediaPlayerFileExtensionList.includes(
          getFileExtension(name).toLocaleLowerCase()
        )

        const options = getFileOrFolderDropdown({
          fileOrFolder,
          handleClickRename,
          handleDelete,
          handleDownload,
          handleClickMove,
          ...(isCompatibleWithViwer && { handleView })
        })

        return actionInProgress ? (
          <div className="flex items-center justify-center w-8 h-8 bg-red-400 rounded-full animate-spin">
            <Icon iconName="loader" className="w-5 h-5 text-white" />
          </div>
        ) : (
          <Popover
            referenceElement={<OptionsButton />}
            popperElement={options}
            triggerEvent={'click'}
            placement="auto"
            closeOnEsc
            closeOnClick
            closeOnClickOutside
          />
        )
      },
      cssClasses: ['w-5']
    }
  ]

  return { columns }
}
