import { makeSingleGraphQlRequest } from './base'

const LIST_ALL_SESSIONS = `
{
  session {
    listAll{
      id,
      context{
        userAgent,
        sourceIp,
        logRocketUrl,
        jdltTrackingCookie
      },
      user {
        id,
        name,
        email
      }
    }
  }
}
`

const CREATE_SESSION_LOG = `mutation x($context: session_ContextInput!, $user: session_UserInput!) {
  session{
    add(context: $context, user: $user){
      id,
      context {
        userAgent,
        sourceIp,
        logRocketUrl,
        jdltTrackingCookie
      },
      user {
        id,
        name,
        email
      }
    }
  }
}
`

const EDIT_SESSION_LOG = `mutation x($id: String!, $context: session_ContextInput!) {
  session {
    edit(id: $id, context: $context){
      id,
      context {
        userAgent,
        sourceIp,
        logRocketUrl,
        jdltTrackingCookie
      },
      user {
        id,
        name,
        email
      }
    }
  }
}
`

export const listAllSessions = async (idToken: string): Promise<any> =>
  await makeSingleGraphQlRequest(LIST_ALL_SESSIONS, idToken)

export const createSessionLog = async (
  idToken: string,
  user: { id: string; name: string; email: string },
  context: {
    logRocketUrl: string
  } = {
    logRocketUrl: ''
  }
): Promise<any> =>
  await makeSingleGraphQlRequest(CREATE_SESSION_LOG, idToken, {
    context,
    user
  })

export const editSessionLog = async (
  idToken: string,
  id: string,
  context: {
    logRocketUrl: string
    jdltTrackingCookie: string
  }
): Promise<any> =>
  await makeSingleGraphQlRequest(EDIT_SESSION_LOG, idToken, { id, context })
