import React, {
  useContext,
  useState,
  ChangeEvent,
  useEffect,
  useCallback
} from 'react'
import { UserContext, DataContext, setLocalStorage } from '../utils'
import {
  ContextSwitcher,
  ContextSwitcherProps,
  Popover,
  Dropdown,
  Button,
  Icon
} from '@jdlt-ltd/pongo'
import { useNavigate, useLocation } from 'react-router-dom'

type ClientContextSwitcherProps = {
  cssClasses?: string[]
}

export const ClientContextSwitcher: React.FC<ClientContextSwitcherProps> = ({
  cssClasses = []
}: ClientContextSwitcherProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchValue, setSearchValue] = useState('')

  const {
    currentUserClients,
    setActiveClient,
    activeClient,
    hasSettingsPermissions
  } = useContext(UserContext)

  const {
    isLoadingAssigningUserToClient,
    clientData,
    assignUserToClient,
    currentUserId
  } = useContext(DataContext)

  const handleClientContextChange = useCallback(
    (client: ContextSwitcherProps['activeContext']) => {
      setActiveClient(client)
      setLocalStorage('activeClient', client)
      navigate(`/docs/${client.id}`)
    },
    [navigate, setActiveClient]
  )

  useEffect(() => {
    const [route, clientId] = pathname.split('/').filter(Boolean)

    const clientFromUrl = currentUserClients?.find(({ id }) => id === clientId)

    const isDocs = route === 'docs'

    if (route && !isDocs) return

    if (clientFromUrl && (!activeClient || activeClient.id !== clientId)) {
      handleClientContextChange(
        clientFromUrl as ContextSwitcherProps['activeContext']
      )
    }

    if (!clientFromUrl && activeClient) {
      navigate(`/docs/${activeClient.id}`)
    }

    if (!clientFromUrl && !activeClient) {
      navigate(`/docs/noClient`)
    }
  }, [
    activeClient,
    currentUserClients,
    handleClientContextChange,
    navigate,
    pathname
  ])

  const searchOptions = clientData
    .filter(({ name, active }) => {
      return active && name.toLowerCase().includes(searchValue.toLowerCase())
    })
    .map(({ name, id }) => {
      return {
        content: <div className="break-all">{name}</div>,
        onClick: () => assignUserToClient([[currentUserId], id])
      }
    })

  return currentUserClients && currentUserClients.length > 0 && activeClient ? (
    <ContextSwitcher
      contextName="client"
      contextData={currentUserClients as { name: string; id: string }[]}
      activeContext={activeClient}
      onContextChange={handleClientContextChange}
      cssClasses={cssClasses}
    />
  ) : hasSettingsPermissions ? (
    <Popover
      referenceElement={
        <Button
          pill
          cssClasses={['bg-indigo-500', 'hover:bg-indigo-400']}
          disabled={isLoadingAssigningUserToClient}
          content={
            <>
              Assign client
              {isLoadingAssigningUserToClient && (
                <div className="ml-2 animate-spin">
                  <Icon iconName="loader" className="w-5 h-5 text-white" />
                </div>
              )}
            </>
          }
        />
      }
      popperElement={
        <Dropdown
          search={{
            onChange: (e: ChangeEvent, { value }) => setSearchValue(value),
            value: searchValue,
            placeholder: `Search clients`
          }}
          options={searchOptions}
        />
      }
      placement={'bottom'}
      triggerEvent={'click'}
      closeOnClickOutside
      onCloseFunc={() => setSearchValue('')}
    />
  ) : null
}
