import { FileOrFolder, BaseFileOrFolder } from './types'
import { sortEntityAlphabetically } from './formattingFuncs'
import {
  getObjectsForPath,
  S3ObjectsData,
  S3File
} from '../services/graphQl/s3'
import { IconName } from '@jdlt-ltd/pongo'

export const checkIsDuplicateName = (
  folderData: FileOrFolder[] | BaseFileOrFolder[],
  nameToCheck: string
): boolean =>
  folderData.some(
    ({ name }) => name.toLowerCase() === nameToCheck.toLowerCase()
  )

export const separateFilesToUploadFromDuplicates = (
  fileList: File[],
  currentFolderData: FileOrFolder[]
): {
  duplicates: File[]
  filesToUpload: File[]
} => {
  const { duplicates, filesToUpload } = fileList.reduce(
    (acc: { duplicates: File[]; filesToUpload: File[] }, currFile: File) => {
      const isDuplicateName = checkIsDuplicateName(
        currentFolderData,
        currFile.name
      )
      return isDuplicateName
        ? {
            ...acc,
            duplicates: [currFile, ...acc.duplicates]
          }
        : {
            ...acc,
            filesToUpload: [currFile, ...acc.filesToUpload]
          }
    },
    { duplicates: [], filesToUpload: [] }
  )
  return { duplicates, filesToUpload }
}

export const createFullPath = (
  existingPath: string,
  folderOrFileName: string
): string =>
  existingPath ? `${existingPath}/${folderOrFileName}` : folderOrFileName

export const getLastItemInArray = (array: any[] | string): any =>
  array[array.length - 1]

export const getFileOrFolderNameFromPath = (path: string): string =>
  getLastItemInArray(path.split('/'))

export const getParentFolderPathFromPath = (path: string): string => {
  const fileOrFolderName = getFileOrFolderNameFromPath(path)
  const folderPath = path.split(fileOrFolderName)[0]
  return getLastItemInArray(folderPath) === '/'
    ? folderPath.substr(0, folderPath.length - 1)
    : folderPath
}

export const getFileAndFolderPath = (
  pathFromUrl: string,
  clientId: string
): { path: string; containsFileName: any; fileName: string | undefined } => {
  const localPath = decodeURIComponent(pathFromUrl)
    .split(`/docs/${clientId}`)
    .join(``)
  const correctLocalPath = localPath.startsWith(`/`)
    ? localPath.substr(1)
    : localPath
  const containsFileName = correctLocalPath
    .split(`/`)
    [correctLocalPath.split(`/`).length - 1].includes(`.`) // TODO: is this logic adequate?
  const folderPath = containsFileName
    ? correctLocalPath
        .split(`/`)
        .filter((x, i) => i < correctLocalPath.split(`/`).length - 1)
        .join(`/`)
    : correctLocalPath
  const correctFolderPath = folderPath.endsWith(`/`)
    ? folderPath.substr(0, folderPath.length - 1)
    : folderPath

  return {
    path: correctFolderPath,
    containsFileName,
    fileName: containsFileName ? correctLocalPath.split(`/`).pop() : ``
  }
}

const fileExtensionIconMap: { [key: string]: IconName } = {
  aif: `fileAudio`,
  mp3: `fileAudio`,
  ogg: `fileAudio`,
  flac: `fileAudio`,
  wav: `fileAudio`,
  wma: `fileAudio`,
  doc: `fileWord`,
  docx: `fileWord`,
  bmp: `fileImage`,
  gif: `fileImage`,
  jpg: `fileImage`,
  jpeg: `fileImage`,
  psd: `fileImage`,
  pages: `fileWord`,
  pdf: `filePdf`,
  png: `fileImage`,
  pps: `filePowerpoint`,
  ppt: `filePowerpoint`,
  pptx: `filePowerpoint`,
  svg: `fileImage`,
  tif: `fileImage`,
  ico: `fileImage`,
  tiff: `fileImage`,
  webp: `fileImage`,
  rtf: `fileAlt`,
  txt: `fileAlt`,
  avi: `fileVideo`,
  m4v: `fileVideo`,
  mkv: `fileVideo`,
  mov: `fileVideo`,
  mp4: `fileVideo`,
  mpg: `fileVideo`,
  mpeg: `fileVideo`,
  webm: `fileVideo`,
  csv: `fileExcel`,
  xls: `fileExcel`,
  xlsm: `fileExcel`,
  xlsx: `fileExcel`,
  '7z': `fileArchive`,
  rar: `fileArchive`,
  tar: `fileArchive`,
  gz: `fileArchive`,
  zip: `fileArchive`,
  html: `fileCode`
}

export const mediaPlayerFileExtensionList = [
  'jpeg',
  'png',
  'tiff',
  'jpg',
  'gif',
  'ico',
  'svg',
  'webp',
  'pdf',
  'mp4',
  'webm',
  'avi',
  'mpeg',
  'mp3',
  'wav',
  'ogg',
  'txt'
]

const fileIconColorMap: { [key: string]: string } = {
  fileExcel: 'text-green-500',
  fileVideo: 'text-red-600',
  fileArchive: 'text-yellow-500',
  fileImage: 'text-pink-500',
  fileAlt: 'text-gray-400',
  filePowerpoint: 'text-orange-500',
  fileAudio: 'text-indigo-500',
  fileWord: 'text-blue-500',
  filePdf: 'text-red-600',
  fileCode: 'text-yellow-600',
  file: 'txt-gray-400'
}

export const getIcon = (fileExtension: string): IconName => {
  const fileExtensionLowerCase = fileExtension.toLocaleLowerCase()
  return fileExtensionIconMap[fileExtensionLowerCase]
    ? fileExtensionIconMap[fileExtensionLowerCase]
    : `file`
}

export const getFileExtension = (fileName: string): string => {
  return fileName.substring(fileName.lastIndexOf(`.`) + 1)
}

const mediaTypeMap: { [key: string]: string } = {
  avi: 'video/avi',
  ogv: 'video/ogg',
  ogm: 'video/ogg',
  ogg: 'video/ogg',
  mp4: 'video/mp4',
  webm: 'video/webm',
  mov: 'video/mp4',
  opus: 'audio/ogg',
  mp3: 'audio/mpeg',
  wav: 'audio/wav',
  gif: 'image/gif'
}

export const getFileExtensionFromUrl = (url: string): string => {
  const urlWithoutHeaders = url?.split('?')[0]
  return urlWithoutHeaders?.substring(url.lastIndexOf(`.`) + 1)
}

export const getMediaMimeType = (url: string): string => {
  const fileExtension = getFileExtensionFromUrl(url)
  return mediaTypeMap[fileExtension]
}

export const formatFileAndFolderData = (
  folders: string[],
  files: S3File[]
): { folderData: any[]; fileData: any[] } => {
  const folderData = folders
    ?.map((folderName: string) => ({
      name: folderName,
      iconName: `folder` as IconName,
      iconCssClasses: ['text-yellow-300'],
      type: 'folder' as FileOrFolder['type']
    }))
    .sort(sortEntityAlphabetically)

  const fileData = files
    ? files
        .map((file: S3File) => {
          const iconName = getIcon(getFileExtension(file.name))
          return {
            name: file.name,
            lastModified: file.lastModified.toString(),
            size: file.size,
            iconName: iconName,
            iconCssClasses: [fileIconColorMap[iconName]],
            type: 'file' as FileOrFolder['type'],
            path: file.path
          }
        })
        .sort(sortEntityAlphabetically)
    : []

  return {
    folderData,
    fileData
  }
}

export const getObjectsForPathAndFormatData = (
  s3Data: S3ObjectsData,
  currentFolderPath: string
): { folderData: any[]; fileData: any[] } => {
  const { folders, files } = getObjectsForPath(s3Data, currentFolderPath)

  return formatFileAndFolderData(folders, files)
}
