export const getPrefixedKey = (
  prefix: string | undefined,
  value: string
): string => `${prefix || ``}${value}`

export const getLocalStorage = (key: string, defaultValue = ``): string => {
  const rawValue = localStorage.getItem(key)
  return !rawValue || rawValue === `undefined`
    ? defaultValue
    : JSON.parse(rawValue)
}

export const setLocalStorage = (key: string, data: any): void =>
  localStorage.setItem(key, JSON.stringify(data))

export const getActiveCompanyFromLocalStorage = (prefix?: string): any =>
  getLocalStorage(getPrefixedKey(prefix, 'activeClient'))
