import React, { useContext, Dispatch, useState } from 'react'
import {
  moveFile as s3MoveFile,
  S3ObjectsData
} from '../../../services/graphQl/s3'
import {
  checkIsDuplicateName,
  getParentFolderPathFromPath,
  getFileOrFolderNameFromPath,
  UserContext,
  getObjectsForPathAndFormatData,
  encodeUrlPath
} from '../../index'
import { Link } from 'react-router-dom'
import { NotificationContext } from '@jdlt-ltd/pongo'

type MoveFileProps = {
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>
}

export const useMoveFile = ({
  s3Data,
  setS3Data
}: MoveFileProps): {
  startMoveFile: (
    moveFilePath: string,
    moveFileDestinationPath: string
  ) => Promise<any>
  isMovingFileOrFolder: boolean
} => {
  const [isMovingFileOrFolder, setIsMovingFileOrFolder] = useState(false)
  const { activeClient, sessionId, idToken } = useContext(UserContext)
  const { errorNotification, successNotification } =
    useContext(NotificationContext)

  const startMoveFile = async (
    moveFilePath: string,
    moveFileDestinationPath: string
  ) => {
    const clientId = activeClient?.id ? activeClient.id : ''
    const moveFileParentFolderPath = getParentFolderPathFromPath(moveFilePath)
    const moveFileName = getFileOrFolderNameFromPath(moveFilePath)

    const { folderData, fileData } = getObjectsForPathAndFormatData(
      s3Data as S3ObjectsData,
      moveFileDestinationPath
    )

    if (
      s3Data &&
      checkIsDuplicateName([...folderData, ...fileData], moveFileName)
    )
      return errorNotification({
        title: `Error`,
        message: `File name already in use in this directory`
      })

    try {
      setIsMovingFileOrFolder(true)
      const updatedS3Data = await s3MoveFile(
        idToken as string,
        sessionId,
        clientId,
        moveFileParentFolderPath,
        moveFileName,
        moveFileDestinationPath
      )
      setS3Data(updatedS3Data)
      const moveFolderUrlPath = `/${clientId}/docs${
        moveFileDestinationPath ? `/${moveFileDestinationPath}` : ''
      }`
      const linkClassName = 'text-blue-500 underline'
      successNotification({
        title: (
          <>
            Moved{' '}
            <Link
              className={linkClassName}
              to={encodeUrlPath(`${moveFolderUrlPath}/${moveFileName}`)}
            >
              {moveFileName}
            </Link>{' '}
            into{' '}
            <Link
              className={linkClassName}
              to={encodeUrlPath(moveFolderUrlPath)}
            >
              {getFileOrFolderNameFromPath(moveFileDestinationPath) || clientId}
            </Link>
          </>
        ),
        autoClose: true,
        timerDuration: 2000
      })
      return 'success'
    } catch (errors: any) {
      errorNotification({
        tite: `Upload error`,
        message: errors[0].message || `An error occurred whilst moving file`
      })
    } finally {
      setIsMovingFileOrFolder(false)
    }
  }

  return { startMoveFile, isMovingFileOrFolder }
}
