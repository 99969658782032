import React, { useContext, Dispatch } from 'react'
import { Link } from 'react-router-dom'
import { uploadFiles, S3ObjectsData } from '../../../services/graphQl/s3'
import { getFileOrFolderNameFromPath, encodeUrlPath } from '../../index'
import { UserContext } from '../../userContext'
import { NotificationContext } from '@jdlt-ltd/pongo'

type UseStartUploadProps = {
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>
}

export const useStartUpload = ({
  s3Data,
  setS3Data
}: UseStartUploadProps): ((
  acceptedFiles: File[],
  isOverwrite?: boolean | undefined,
  folderName?: string | undefined
) => Promise<void>) => {
  const { activeClient, currentFolderPath, sessionId, idToken } =
    useContext(UserContext)

  const { inProgressNotification, errorNotification, successNotification } =
    useContext(NotificationContext)

  const startUpload = async (
    acceptedFiles: File[],
    isOverwrite?: boolean,
    folderName?: string
  ) => {
    const clientId = activeClient?.id ? activeClient.id : ''
    const path =
      folderName && currentFolderPath
        ? `${currentFolderPath}/${folderName}`
        : folderName && !currentFolderPath
        ? folderName
        : currentFolderPath

    try {
      const uploadedFiles = await uploadFiles(
        idToken as string,
        sessionId,
        clientId,
        path,
        acceptedFiles,
        setS3Data,
        (fileName: string, progress: number, fileId: string) => {
          inProgressNotification({
            id: fileId,
            title: `Uploading ${fileName}`,
            progress
          })
        },
        isOverwrite
      )

      if (uploadedFiles) {
        const firstFileName = uploadedFiles[0].name
        const folderUrlPath = `/${clientId}/docs${path ? `/${path}` : ''}`
        const linkClassName = 'text-blue-500 underline'
        const folderLinkElement = (
          <Link className={linkClassName} to={encodeUrlPath(folderUrlPath)}>
            {path ? getFileOrFolderNameFromPath(path) : activeClient?.name}
          </Link>
        )

        const fileNames = uploadedFiles.map(({ name }, i) => (
          <li key={`${name}-${i}`}>{name}</li>
        ))

        const title =
          uploadedFiles.length > 1 ? (
            <>
              Added {uploadedFiles.length} files to {folderLinkElement}
            </>
          ) : (
            <>
              Added{' '}
              <Link
                className={linkClassName}
                to={encodeUrlPath(`${folderUrlPath}/${firstFileName}`)}
              >
                {firstFileName}
              </Link>{' '}
              to {folderLinkElement}
            </>
          )

        successNotification({
          tite: `Upload success`,
          autoClose: true,
          timerDuration: 5000,
          closeOnClick: false,
          title,
          isExpand: uploadedFiles.length > 1,
          expandInfo: <ul className="list-disc list-inside">{fileNames}</ul>,
          isPauseOnMouseOrTouchEnter: true
        })
      }
    } catch (errors: any) {
      errorNotification({
        tite: `Upload error`,
        message: errors.length
          ? errors[0].message
          : `An error occurred whilst uploading`
      })
    }
  }
  return startUpload
}
