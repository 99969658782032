import React, { useState, useEffect, SetStateAction, Dispatch } from 'react'
import { S3ObjectsData } from '../../../services/graphQl'
import {
  FileOrFolder,
  separateFilesToUploadFromDuplicates,
  useStartUpload
} from '../../../utils'

type UseUploadOverwriteType = {
  currentFolderData: FileOrFolder[]
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<SetStateAction<S3ObjectsData | null>>
}

export const useUploadFileWithOverwrite = ({
  currentFolderData,
  s3Data,
  setS3Data
}: UseUploadOverwriteType): {
  duplicatefilesToUpload: File[] | null
  setDuplicateFilesToUpload: Dispatch<SetStateAction<File[] | null>>
  setFilesUpload: Dispatch<React.SetStateAction<File[] | null>>
  setOverWrite: Dispatch<React.SetStateAction<File[] | null>>
} => {
  const startUpload = useStartUpload({ s3Data, setS3Data })

  const [filesUpload, setFilesUpload] = useState<File[] | null>(null)
  const [duplicatefilesToUpload, setDuplicateFilesToUpload] = useState<
    File[] | null
  >(null)
  const [dedupeFilesToUpload, setDedupeFilesToUpload] = useState<File[] | null>(
    null
  )

  const [overWrite, setOverWrite] = useState<File[] | null>(null)

  useEffect(() => {
    if (overWrite) {
      const filesToUpload = overWrite
      startUpload(filesToUpload, true)
      setOverWrite(null)
    }
  }, [overWrite, startUpload])

  useEffect(() => {
    if (dedupeFilesToUpload?.length) {
      startUpload(dedupeFilesToUpload)
      setDedupeFilesToUpload(null)
    }
  }, [dedupeFilesToUpload, startUpload])

  useEffect(() => {
    if (filesUpload) {
      const { duplicates, filesToUpload } = separateFilesToUploadFromDuplicates(
        [...filesUpload],
        currentFolderData
      )

      duplicates.length && setDuplicateFilesToUpload(duplicates)
      filesToUpload.length &&
        setDedupeFilesToUpload((currentFiles) =>
          currentFiles
            ? [...currentFiles, ...filesToUpload]
            : [...filesToUpload]
        )

      setFilesUpload(null)
    }
  }, [filesUpload, currentFolderData])

  return {
    duplicatefilesToUpload,
    setDuplicateFilesToUpload,
    setFilesUpload,
    setOverWrite
  }
}
