import React, {
  useState,
  Dispatch,
  MutableRefObject,
  useRef,
  useEffect,
  useContext,
  useCallback
} from 'react'
import {
  Client,
  User,
  Role,
  Permission,
  NotificationContext,
  Error,
  useApiService
} from '@jdlt-ltd/pongo'
import { useErrorNotificationAndSetState } from '../utils'
import {
  assignUserToClient as assignUserToClientApiCall,
  listObjects,
  S3ObjectsData,
  listAllClients,
  listAllRoles,
  listPermissions,
  listUsers
} from '../services/graphQl'
import { UserContext } from './userContext'
import { useAuth0 } from '@auth0/auth0-react'

type ContextProps = {
  clientData: Client[]
  userData: User[]
  roleData: Role[]
  permissionData: Permission[]
  setClientData: Dispatch<React.SetStateAction<Client[]>>
  setUserData: Dispatch<React.SetStateAction<User[]>>
  setRoleData: Dispatch<React.SetStateAction<Role[]>>
  setPermissionData: Dispatch<React.SetStateAction<Permission[]>>
  initialUserManagerDataFetch: MutableRefObject<boolean> | null
  fetchedClientDataId: MutableRefObject<string> | null
  clientWithAssignedUser: Client | null
  assignUserToClient: Function
  isErrorAssigningUserToClient: boolean
  isLoadingAssigningUserToClient: boolean
  setDataWithUpdatedEntity: Function
  currentUserId: string
  isLoadingS3Objects: boolean
  listClientS3Objects: Function
  s3ObjectsFetchErrors: Error[]
  isErrorFetchingS3Objects: boolean
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>
  listClientsApiQuery: Function
  listPermissionsApiQuery: Function
  listRolesApiQuery: Function
  listUsersApiQuery: Function
  getRelatedEntitiesForClient: Function
  getRelatedEntitiesForUser: Function
  isLoadingClients: boolean
  isLoadingRoles: boolean
  isLoadingUsers: boolean
  isLoadingPermissions: boolean
  fetchedS3Objects: S3ObjectsData
}

export const DataContext = React.createContext<ContextProps>({
  clientData: [],
  userData: [],
  roleData: [],
  permissionData: [],
  setClientData: () => null,
  setUserData: () => null,
  setRoleData: () => null,
  setPermissionData: () => null,
  initialUserManagerDataFetch: null,
  fetchedClientDataId: null,
  clientWithAssignedUser: null,
  assignUserToClient: () => null,
  isErrorAssigningUserToClient: false,
  isLoadingAssigningUserToClient: false,
  setDataWithUpdatedEntity: () => null,
  currentUserId: '',
  isLoadingS3Objects: false,
  listClientS3Objects: () => null,
  s3ObjectsFetchErrors: [],
  isErrorFetchingS3Objects: false,
  s3Data: null,
  setS3Data: () => null,
  listClientsApiQuery: () => null,
  listPermissionsApiQuery: () => null,
  listRolesApiQuery: () => null,
  listUsersApiQuery: () => null,
  getRelatedEntitiesForClient: () => null,
  getRelatedEntitiesForUser: () => null,
  isLoadingClients: false,
  isLoadingRoles: false,
  isLoadingUsers: false,
  isLoadingPermissions: false,
  fetchedS3Objects: {}
})

type Props = {
  children: React.ReactNode
}

export const DataContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [clientData, setClientData] = useState<Client[]>([])
  const [userData, setUserData] = useState<User[]>([])
  const [roleData, setRoleData] = useState<Role[]>([])
  const [permissionData, setPermissionData] = useState<Permission[]>([])
  const [s3Data, setS3Data] = useState<S3ObjectsData | null>(null)
  const initialUserManagerDataFetch = useRef<boolean>(true)
  const fetchedClientDataId = useRef<string>('')

  const { errorNotification } = useContext(NotificationContext)
  const { setCurrentUserClients, idToken } = useContext(UserContext)

  const { user: currentUser } = useAuth0()

  const activeUser = userData.find(
    (user: User) => user.emailAddress === currentUser?.email
  ) as User

  const [
    {
      data: clientWithAssignedUser,
      apiQueryFunc: assignUserToClient,
      isError: isErrorAssigningUserToClient,
      isLoading: isLoadingAssigningUserToClient
    },
    {
      data: fetchedS3Objects,
      isLoading: isLoadingS3Objects,
      apiQueryFunc: listClientS3Objects,
      errors: s3ObjectsFetchErrors,
      isError: isErrorFetchingS3Objects
    },
    {
      data: clients,
      isLoading: isLoadingClients,
      apiQueryFunc: listClientsApiQuery,
      errors: fetchClientsErrors,
      isError: isErrorFetchingClients
    },
    {
      data: roles,
      isLoading: isLoadingRoles,
      apiQueryFunc: listRolesApiQuery,
      errors: fetchRolesErrors,
      isError: isErrorFetchingRoles
    },
    {
      data: users,
      isLoading: isLoadingUsers,
      apiQueryFunc: listUsersApiQuery,
      errors: fetchUsersErrors,
      isError: isErrorFetchingUsers
    },
    {
      data: permissions,
      isLoading: isLoadingPermissions,
      apiQueryFunc: listPermissionsApiQuery,
      errors: fetchPermissionsErrors,
      isError: isErrorFetchingPermissions
    }
  ] = [
    useApiService(
      assignUserToClientApiCall,
      [],
      null,
      false,
      idToken as string
    ),
    useApiService(listObjects, [], null, false, idToken as string),
    useApiService(listAllClients, [], null, false, idToken as string),
    useApiService(listAllRoles, [], null, false, idToken as string),
    useApiService(listUsers, [], null, false, idToken as string),
    useApiService(listPermissions, [], null, false, idToken as string)
  ]

  useEffect(() => {
    setS3Data(fetchedS3Objects)
  }, [fetchedS3Objects])

  const getRelatedEntitiesForClient = useCallback(
    ({ users }: Client) => ({
      users
    }),
    []
  )

  const getRelatedEntitiesForUser = useCallback(
    (userId: string) => {
      const usersClients = clientData.filter((client: Client) =>
        client.users?.some(({ id }: User) => id === userId)
      )

      const usersRoles = roleData.filter((role: Role) =>
        role.users?.some(({ id }: User) => id === userId)
      )

      const userPermissions = usersRoles.reduce(
        (acc: Permission[], { permissions }: Role) => {
          return [
            ...acc,
            ...permissionData.filter(({ slug }) => permissions.includes(slug))
          ]
        },
        []
      )
      return {
        clients: usersClients,
        roles: usersRoles,
        permissions: userPermissions
      }
    },
    [clientData, roleData, permissionData]
  )

  const formatPermissions = (
    rolePermissions: string[],
    permissionList: Permission[]
  ) =>
    rolePermissions.reduce((acc: Permission[], currPermission: string) => {
      const fullPermissionObject = permissionList.find(
        ({ slug }) => slug === currPermission
      )
      return fullPermissionObject
        ? [
            ...acc,
            {
              ...(fullPermissionObject as Permission),
              id: currPermission
            }
          ]
        : acc
    }, [])

  const getRelatedEntitiesForRole = useCallback(
    (role: Role, permissionList: Permission[]) => ({
      users: role.users,
      permissions: formatPermissions(role.permissions, permissionList)
    }),
    []
  )

  const getRelatedEntitiesForPermission = useCallback(
    (permissionId: string) => {
      const rolesContainingPermission = roleData.filter(
        ({ permissions }: Role) => {
          return permissions.includes(permissionId)
        }
      )
      const usersWithPermission = rolesContainingPermission.reduce(
        (acc: User[], { users }: Role) => [...acc, ...(users as User[])],
        []
      )
      return {
        roles: rolesContainingPermission,
        users: usersWithPermission
      }
    },
    [roleData]
  )

  useEffect(() => {
    if (clients) {
      setClientData(
        clients.map((client: Client) => {
          return {
            ...client,
            getRelatedEntities: (client: Client) =>
              getRelatedEntitiesForClient(client)
          }
        })
      )
    }
  }, [clients, getRelatedEntitiesForClient, setClientData])

  useEffect(() => {
    setUserData((users) =>
      users.map((user: User) => {
        return {
          ...user,
          getRelatedEntities: ({ id }: User) =>
            getRelatedEntitiesForUser(id as string),
          active: !user.blocked
        }
      })
    )
  }, [getRelatedEntitiesForUser, setUserData])

  useEffect(() => {
    if (users) {
      setUserData(users)
    }
  }, [users, setUserData])

  useEffect(() => {
    if (roles && permissions) {
      setRoleData(
        roles.map((role: Role) => {
          return {
            ...role,
            getRelatedEntities: (role: Role) =>
              getRelatedEntitiesForRole(role, permissions)
          }
        })
      )
    }
  }, [roles, getRelatedEntitiesForRole, permissions, setRoleData])

  useEffect(() => {
    if (permissions) {
      setPermissionData(
        permissions.map((permission: Permission) => {
          return {
            ...permission,
            id: permission.slug,
            getRelatedEntities: ({ slug }: Permission) =>
              getRelatedEntitiesForPermission(slug)
          }
        })
      )
    }
  }, [permissions, getRelatedEntitiesForPermission, setPermissionData])

  useEffect(() => {
    if (clientData.length > 0 && activeUser && activeUser.id) {
      const clientsForCurrentUser = getRelatedEntitiesForUser(
        activeUser.id
      ).clients.filter(({ active }) => active)
      const updatedClientsForCurrentUser = clientsForCurrentUser.reduce(
        (acc: {}[], currClient: Client) => {
          return currClient.active
            ? [
                ...acc,
                {
                  id: currClient.slug,
                  name: currClient.name
                }
              ]
            : acc
        },
        []
      )
      setCurrentUserClients(updatedClientsForCurrentUser)
    }
  }, [activeUser, clientData, getRelatedEntitiesForUser, setCurrentUserClients])

  const setDataWithUpdatedEntity = useCallback(
    (
      entityId: string,
      updatedData: { key: string; value: any },
      setStateFunction: Dispatch<React.SetStateAction<any[]>>
    ) => {
      setStateFunction((entities) => {
        return entities.map((currentEntity) => {
          const isUpdatedEntity = entityId === currentEntity.id
          return isUpdatedEntity
            ? { ...currentEntity, [updatedData.key]: updatedData.value }
            : currentEntity
        })
      })
    },
    []
  )

  useEffect(() => {
    if (isErrorAssigningUserToClient) {
      errorNotification({
        title: `Error`,
        message: `Error assigning user to client`
      })
    }

    if (clientWithAssignedUser?.id) {
      setDataWithUpdatedEntity(
        clientWithAssignedUser.id,
        { key: 'users', value: clientWithAssignedUser.users },
        setClientData
      )
    }
  }, [
    errorNotification,
    isErrorAssigningUserToClient,
    clientWithAssignedUser,
    setClientData,
    setDataWithUpdatedEntity
  ])

  useEffect(() => {
    if (isErrorFetchingS3Objects) {
      errorNotification({
        title: `Error`,
        message: `There was an error fetching documents for this client`
      })
      setS3Data(null)
    }
  }, [errorNotification, isErrorFetchingS3Objects])

  useErrorNotificationAndSetState(
    isErrorFetchingClients,
    fetchClientsErrors,
    setClientData,
    []
  )
  useErrorNotificationAndSetState(
    isErrorFetchingPermissions,
    fetchPermissionsErrors,
    setPermissionData,
    []
  )
  useErrorNotificationAndSetState(
    isErrorFetchingRoles,
    fetchRolesErrors,
    setRoleData,
    []
  )
  useErrorNotificationAndSetState(
    isErrorFetchingUsers,
    fetchUsersErrors,
    setUserData,
    []
  )

  const context = {
    clientData,
    userData,
    roleData,
    permissionData,
    setClientData,
    setUserData,
    setRoleData,
    setPermissionData,
    initialUserManagerDataFetch,
    fetchedClientDataId,
    clientWithAssignedUser,
    assignUserToClient,
    isErrorAssigningUserToClient,
    isLoadingAssigningUserToClient,
    setDataWithUpdatedEntity,
    currentUserId: activeUser?.id,
    isLoadingS3Objects,
    listClientS3Objects,
    s3ObjectsFetchErrors,
    isErrorFetchingS3Objects,
    s3Data,
    setS3Data,
    listClientsApiQuery,
    listPermissionsApiQuery,
    listRolesApiQuery,
    listUsersApiQuery,
    getRelatedEntitiesForClient,
    getRelatedEntitiesForUser,
    isLoadingClients,
    isLoadingRoles,
    isLoadingUsers,
    isLoadingPermissions,
    fetchedS3Objects
  }

  return <DataContext.Provider value={context}>{children}</DataContext.Provider>
}
