import DevConfig from './envs/DevConfig'
import TestConfig from './envs/TestConfig'
import ProductionConfig from './envs/ProductionConfig'
import DefaultConfig from './DefaultConfig'

const env = process.env.REACT_APP_ENV || `dev`

type ConfigType = { [key: string]: any }

class Config {
  config: ConfigType
  constructor() {
    this.config = this.populate([DefaultConfig, this.getEnvConfig()])
  }

  get = () => this.config

  populate = (configs: ({} | ConfigType)[]) => {
    configs.forEach(
      (config: {} | ConfigType) =>
        (this.config = this.populateData(this.config, config))
    )
    return this.config
  }

  populateData = (baseData: ConfigType, envData: {} | ConfigType) => {
    const data = { ...baseData }

    return this.recursivelyPopulateData(data, envData)
  }

  recursivelyPopulateData = (
    data: ConfigType,
    envData: {} | ConfigType
  ): ConfigType =>
    Object.entries(envData).reduce(
      (configAcc, [key, value]) =>
        typeof value !== `object`
          ? { ...configAcc, [key]: value }
          : {
              ...configAcc,
              [key]: this.recursivelyPopulateData(configAcc[key] || {}, value)
            },
      data
    )

  getEnvConfig = (): {} => {
    switch (env) {
      case `production`:
        return ProductionConfig
      case `test`:
        return TestConfig
      case `dev`:
      default:
        return DevConfig
    }
  }
}

export default new Config()
