import React from 'react'
import { Icon } from '@jdlt-ltd/pongo'

export const OptionsButton = (): JSX.Element => (
  <button
    aria-haspopup="true"
    type="button"
    className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 detail-button"
  >
    <Icon iconName="verticalDots" cssClasses={['w-5', 'h-5']} />
  </button>
)
