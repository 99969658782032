import { assign, createMachine } from 'xstate'
import { getFileExtension } from '../../utils'

export interface FetchUrlToViewFileMachineContext {
  variables?: any
  fileUrl?: string
  isLoaded?: boolean | undefined
  errorMessage?: string
  content?: string
}

const isTextFile = (fileName: string) =>
  getFileExtension(fileName).toLocaleLowerCase() === 'txt'

const isPdf = (fileName: string) =>
  getFileExtension(fileName).toLocaleLowerCase() === 'pdf'

export type FetchUrlToViewFileMachineEvent = {
  type: 'FETCH' | 'LOAD' | 'FAIL'
  variables: any
}

const handledFiles = [
  'png',
  'jpeg',
  'tiff',
  'opus',
  'mp3',
  'wav',
  'avi',
  'ogv',
  'ogm',
  'ogg',
  'mp4',
  'webm',
  'mov',
  'jpg',
  'gif',
  'ico',
  'svg',
  'webp'
]

export const fetchUrlToViewFileMachine = createMachine<
  FetchUrlToViewFileMachineContext,
  FetchUrlToViewFileMachineEvent
>(
  {
    id: 'fetchUrlToViewFileMachine',
    initial: 'idle',
    states: {
      idle: {
        on: {
          FETCH: {
            target: 'fetchingUrl',
            actions: 'assignVariables'
          }
        }
      },
      fetchingUrl: {
        invoke: {
          src: 'fetchURL',
          onError: {
            target: 'errored',
            actions: 'assignErrorToContext'
          },
          onDone: [
            {
              target: 'fetchedUrl',
              actions: 'assignFileUrlToContext'
            }
          ]
        }
      },
      fetchedUrl: {
        always: [
          { target: 'loadingUrl', cond: 'isMedia' },
          { target: 'loadedUrl', cond: 'isSupported' },
          { target: 'failedToLoadUrl', cond: 'isNotSupported' }
        ]
      },
      loadingUrl: {
        on: {
          LOAD: 'loadedUrl',
          FAIL: 'failedToLoadUrl'
        }
      },
      loadedUrl: { always: { target: 'complete', actions: 'assignIsLoaded' } },
      failedToLoadUrl: {
        always: [
          {
            target: 'complete',
            actions: 'assignIsFailed',
            cond: 'isNotTextFile'
          },
          { target: 'getText', cond: 'isTextFile' }
        ]
      },
      getText: {
        invoke: {
          src: 'getText',
          onDone: {
            target: 'complete',
            actions: ['saveText', 'assignIsLoaded']
          },
          onError: {
            target: 'errored',
            actions: 'assignErrorToContext'
          }
        }
      },
      complete: { on: { FETCH: 'idle' } },
      errored: {
        on: { FETCH: { target: 'idle', actions: 'clearErrorMessage' } }
      }
    }
  },
  {
    actions: {
      assignFileUrlToContext: assign({
        fileUrl: (_context, event: any) => event.data
      }),
      assignIsLoaded: assign({
        isLoaded: (_context) => true
      }),
      assignIsFailed: assign({
        isLoaded: (_context) => false
      }),
      saveText: assign({ content: (context, event: any) => event.data }),
      assignErrorToContext: assign({
        errorMessage: (_context, event: any) =>
          event.data[0]?.message || 'An unknown error occurred'
      }),
      clearErrorMessage: assign({
        errorMessage: (_context) => undefined
      }),
      assignVariables: assign({
        variables: (_context, event: any) => event.variables
      })
    },
    guards: {
      isMedia: (context) =>
        handledFiles.includes(getFileExtension(context.variables[4])),
      isSupported: (context) => isPdf(context.variables[4]),
      isNotSupported: (context) =>
        !isPdf(context.variables[4]) &&
        !handledFiles.includes(getFileExtension(context.variables[4])),
      isTextFile: (context) => isTextFile(context.variables[4]),
      isNotTextFile: (context) => !isTextFile(context.variables[4])
    },
    services: {
      getText: async (context) => {
        return (await fetch(context.fileUrl as string)).text()
      }
    }
  }
)
