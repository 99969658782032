import axios, { AxiosRequestConfig } from 'axios'

import config from '../../config'

export const makeGraphQlRequest = async (
  query: string,
  idToken: string,
  variables: { [key: string]: any }
): Promise<any> => {
  const {
    graphql: {
      server: { baseUrl, url }
    }
  } = config.get()

  const data = {
    operationName: ``,
    query: query,
    variables
  }

  const params = {
    method: `POST`,
    url: `${baseUrl}/${url}`,
    data,
    headers: {
      [`Authorization`]: `Bearer ${idToken}`
    },
    responseType: `json`
  } as AxiosRequestConfig

  const { data: responseData } = await axios(params).catch((error) => {
    console.log(`Failure response from GraphQL`, error)

    throw error
  })

  if (!responseData) throw new Error(`No response data returned by request`)

  const { data: graphQlData, errors } = responseData

  if (errors && errors.length > 0) {
    console.log(`Error response from GraphQL`, errors)
    // displayErrorMessage(errors)

    throw errors
  }

  if (!graphQlData) throw new Error(`No GraphQL data returned by request`)

  console.log(`Success response from GraphQL`, graphQlData)

  return graphQlData
}

export const makeSingleGraphQlRequest = async (
  query: string,
  idToken: string,
  variables: { [key: string]: any } = {}
): Promise<any> => {
  const graphQlData: {
    [key: string]: { [key: string]: any }
  } = await makeGraphQlRequest(query, idToken, variables)

  return Object.values(Object.values(graphQlData)[0])[0]
}

// TODO: this code will be looked at for error handling once Notification is in dev
// const errorMap: { [key: string]: string } = {
//   MalformedXML: `The folder has already been deleted or does not exist`
// }
// const displayErrorMessage = (errors: { [key: string]: string }[]) => {
//   const formattedErrors = errors.map((error) => {
//     const parsedMessage = JSON.parse(error.message.replace(`AWS error: `, ``))
//     const code = parsedMessage.code
//     return code
//   })
//   return formattedErrors.map((error: string) =>
//     errorMap[error] ? toastie.error(errorMap[error]) : null
//   )
// }
