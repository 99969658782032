import React from 'react'
import { ClientContextSwitcher } from './index'
import { SettingsButton } from './common'
import { Popover, Avatar, Dropdown, DropdownItemProps } from '@jdlt-ltd/pongo'
import { MenuOption } from '../utils'
import { useAuth0 } from '@auth0/auth0-react'

type NavBarProps = {
  menuOptions: MenuOption[]
}

export const NavBar: React.FC<NavBarProps> = ({ menuOptions }: NavBarProps) => {
  const { user } = useAuth0()

  const menuItems = menuOptions.map(
    ({ name, onClick, divided }): DropdownItemProps => {
      return { content: name, onClick: () => onClick(), divided }
    }
  )

  return (
    <>
      <SettingsButton />
      <div className="flex justify-between flex-1 md:px-4">
        <div className="flex items-center ml-4 md:ml-6">
          <ClientContextSwitcher cssClasses={['max-w-xs', 'truncate']} />
          <div className="relative ml-3">
            <Popover
              referenceElement={
                <Avatar size="sm" isButton src={user?.picture} />
              }
              popperElement={
                <div className="pt-8">
                  <Dropdown options={menuItems} />
                </div>
              }
              stopPropagation
              triggerEvent={'click'}
              placement="bottom"
              closeOnClick
              closeOnClickOutside
              closeOnEsc
            />
          </div>
        </div>
      </div>
    </>
  )
}
