import React, { useEffect } from 'react'
import { useMachine } from '@xstate/react'
import { getFileExtension, fetchUrlToViewFileMachine } from '../../utils'
import { MediaPlayer, ImageViewer } from '@jdlt-ltd/pongo'

type UrlToViewFileQueryProps = {
  variables: (string | undefined | null)[]
  onComplete?: (args: {
    isLoaded?: boolean
    fileUrl?: string
    content?: string
  }) => void
  onError?: (arg: any) => void
  apiQueryFunc: (...args: any[]) => any
}

export const UrlToViewFileQuery: React.FC<UrlToViewFileQueryProps> = ({
  variables,
  onComplete,
  onError,
  apiQueryFunc
}: UrlToViewFileQueryProps) => {
  const extension = getFileExtension(variables[4] || '')

  const [fetchMachineState, sendToFetchMachine] = useMachine(
    fetchUrlToViewFileMachine,
    {
      services: {
        fetchURL: (context) => {
          const { variables } = context
          return apiQueryFunc(...variables)
        }
      }
    }
  )

  useEffect(() => {
    sendToFetchMachine('FETCH', { variables })
  }, [sendToFetchMachine, variables])

  const { fileUrl, isLoaded, errorMessage, content } = fetchMachineState.context

  const imageFiles = ['png', 'jpeg', 'tiff', 'jpg', 'gif', 'ico', 'svg', 'webp']

  const mediaFiles = [
    'opus',
    'mp3',
    'wav',
    'avi',
    'ogv',
    'ogm',
    'ogg',
    'mp4',
    'webm',
    'mov'
  ]

  useEffect(() => {
    if (fetchMachineState.matches('errored')) {
      onError && onError(errorMessage)
    }

    if (fetchMachineState.matches('complete')) {
      onComplete && onComplete({ fileUrl, isLoaded, content })
    }
  }, [
    fetchMachineState,
    onComplete,
    fileUrl,
    onError,
    errorMessage,
    isLoaded,
    content
  ])

  if (
    fetchMachineState.matches('loadingUrl') &&
    imageFiles.includes(extension)
  ) {
    return (
      <ImageViewer
        onImageLoaded={(isLoaded) => {
          if (isLoaded) {
            sendToFetchMachine('LOAD')
          }
        }}
        cssClasses={['hidden']}
        src={fileUrl}
        onImageFailed={(isFailed) => {
          if (isFailed) {
            sendToFetchMachine('FAIL')
          }
        }}
      />
    )
  }

  if (
    fetchMachineState.matches('loadingUrl') &&
    mediaFiles.includes(extension)
  ) {
    return (
      <MediaPlayer
        onMediaLoaded={(isLoaded) => {
          if (isLoaded) {
            sendToFetchMachine('LOAD')
          }
        }}
        cssClasses={['hidden']}
        src={fileUrl}
        onMediaFailed={(isFailed) => {
          if (isFailed) {
            sendToFetchMachine('FAIL')
          }
        }}
      />
    )
  }

  return null
}
