import React from 'react'
import { Outlet, useParams } from 'react-router'

export const ActiveClient = () => {
  const { clientId } = useParams()

  if (clientId !== 'noClient') {
    return <Outlet />
  }

  return (
    <div className="w-3/4 pt-4 mx-auto">
      <div
        className="px-4 py-3 text-blue-600 bg-blue-100 border border-blue-400"
        role="alert"
      >
        <p className="text-sm">
          {`You don't currently have access to any clients.`}
        </p>
      </div>
    </div>
  )
}
