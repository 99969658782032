import React, { useState, Fragment, useRef } from 'react'
import { Input, Heading, Button } from '@jdlt-ltd/pongo'
import {
  ChangeEventValue,
  containsValidS3FolderChars,
  containsValidS3FileChars
} from '../../utils'

type EditableInputProps = {
  name: string
  handleSaveName: Function
  type?: string
}

export const EditableInput: React.FC<EditableInputProps> = ({
  name,
  type = `folder`,
  handleSaveName
}: EditableInputProps) => {
  const regExToUse =
    type === `folder` ? containsValidS3FolderChars : containsValidS3FileChars

  const [updatedName, setUpdatedName] = useState<string>(name)

  const originalName = useRef(name)

  const [invalidCharacter, setInvalidCharacter] = useState<string | null>(null)

  const handleNameChange = (value: string | number): void => {
    const stringifiedName = value.toString()
    if (regExToUse(stringifiedName)) {
      setUpdatedName(stringifiedName)
    } else {
      const invalidCharacter = stringifiedName
        .split('')
        .filter((char) => !regExToUse(char))
      setInvalidCharacter(`${invalidCharacter}`)
      setTimeout(() => setInvalidCharacter(null), 2000)
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const trimmedName = updatedName.trim()
    if (trimmedName.length > 0 && trimmedName !== originalName.current) {
      handleSaveName(trimmedName, originalName.current)
    }
  }

  return (
    <Fragment>
      <Heading size={3}>Rename {originalName.current}</Heading>
      <form onSubmit={handleSubmit} className="mt-5 sm:flex">
        <div className="w-full max-w-xs">
          <Input
            onChange={(
              _e: React.ChangeEvent,
              { value }: ChangeEventValue
            ): void => {
              handleNameChange(value)
            }}
            value={updatedName}
            autoFocus
            placeholder={originalName.current}
          />
          {invalidCharacter && (
            <div id="error-message" className="h-4 my-2 text-xs text-red-500">
              <kbd className="text-red-500 bg-gray-50 text-sm leading-5 py-0.5 px-1.5 border border-red-300 rounded-md border-b-2">
                {invalidCharacter}
              </kbd>{' '}
              is not a valid character
            </div>
          )}
        </div>
        <div>
          <span className="inline-flex mt-3 rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
            <Button
              type="submit"
              content="Save"
              disabled={!updatedName || updatedName === originalName.current}
            />
          </span>
        </div>
      </form>
    </Fragment>
  )
}
