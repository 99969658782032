import React, {
  Fragment,
  FC,
  useState,
  ChangeEvent,
  useContext,
  Dispatch
} from 'react'
import { Heading, Input, Button } from '@jdlt-ltd/pongo'
import {
  ChangeEventValue,
  containsValidS3FolderChars,
  UserContext,
  FileOrFolder
} from '../../utils'

type NewFolderProps = {
  createNewFolder: Function
  currentFolderData: FileOrFolder[]
  setNewFolderModalOpen: Dispatch<React.SetStateAction<boolean>>
}

export const NewFolder: FC<NewFolderProps> = ({
  createNewFolder,
  currentFolderData,
  setNewFolderModalOpen
}: NewFolderProps) => {
  const { currentFolderPath } = useContext(UserContext)
  const [folderName, setFolderName] = useState(``)
  const [invalidCharacter, setInvalidCharacter] = useState<string | null>(null)

  const handleFolderNameChange = (value: string | number): void => {
    const stringifiedName = value.toString()

    if (containsValidS3FolderChars(stringifiedName)) {
      setFolderName(stringifiedName)
    } else {
      const invalidCharacter = stringifiedName
        .split('')
        .filter((char) => !containsValidS3FolderChars(char))
      setInvalidCharacter(`${invalidCharacter}`)
      setTimeout(() => setInvalidCharacter(null), 2000)
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (folderName)
      createNewFolder(
        currentFolderData,
        currentFolderPath,
        folderName.trim(),
        setNewFolderModalOpen
      )
  }

  return (
    <Fragment>
      <Heading size={3}>New folder</Heading>
      <div className="max-w-xl mt-2 text-sm leading-5 text-gray-500">
        <p>Type the folder name to create a new folder</p>
      </div>
      <form onSubmit={handleSubmit} className="mt-5 sm:flex">
        <div className="w-full max-w-xs">
          <Input
            placeholder="Folder name"
            id="folderName"
            value={folderName}
            label={{ content: 'Folder name', location: 'hidden' }}
            onChange={(_e: ChangeEvent, { value }: ChangeEventValue): void =>
              handleFolderNameChange(value)
            }
            autoFocus
          />
          {invalidCharacter && (
            <div id="error-message" className="h-4 my-2 text-xs text-red-500">
              <kbd className="text-red-500 bg-gray-50 text-sm leading-5 py-0.5 px-1.5 border border-red-300 rounded-md border-b-2">
                {invalidCharacter}
              </kbd>{' '}
              is not a valid character
            </div>
          )}
        </div>
        <div>
          <span className="inline-flex mt-3 rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
            <Button type="submit" content={'Create'} disabled={!folderName} />
          </span>
        </div>
      </form>
    </Fragment>
  )
}
