import { useEffect, useContext } from 'react'
import LogRocket from 'logrocket'
import packageJson from '../../../../package.json'
import ReactGA from 'react-ga'
import { useAuth0 } from '@auth0/auth0-react'
import { UserContext } from '../../../utils'

const isEnvProd = process.env.REACT_APP_ENV === 'production'

export const useLogRocket = (): void => {
  useEffect(() => {
    if (isEnvProd)
      LogRocket.init('andersen-uk/portal', {
        release: packageJson.version
      })
  }, [])
}

export const useLogRocketIdentify = (): void => {
  const { user } = useAuth0()

  const { setIsLogRocket } = useContext(UserContext)

  useEffect(() => {
    if (isEnvProd && user) {
      setIsLogRocket(true)
      LogRocket.identify(user.email || '', {
        name: user.name || '',
        email: user.email || ''
      })

      LogRocket.getSessionURL((sessionURL) => {
        ReactGA.event({
          category: 'LogRocket',
          action: sessionURL
        })
      })
    }
  }, [user, setIsLogRocket])
}
