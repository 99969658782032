import React, { useContext } from 'react'
import { StackedLayout, BaseTypes, getClassName } from '@jdlt-ltd/pongo'
import { NavBar, UserMenu, AnimatedLogo, Footer } from './index'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { SettingsButton } from './common'
import { useAuth0 } from '@auth0/auth0-react'
import { UserContext } from '../utils'

type DisplayPageProps = BaseTypes<JSX.IntrinsicElements['div']>

export const NavbarButton = ({
  isHidden,
  name,
  navigateTo,
  isActive
}: {
  isHidden?: boolean
  name: string
  navigateTo: string
  isActive: boolean
}): JSX.Element => {
  const navigate = useNavigate()

  return isHidden ? (
    <></>
  ) : (
    <div
      className={getClassName([
        'inline-flex',
        'items-center',
        'h-full',
        'px-1',
        'mx-3',
        'pt-1',
        'text-sm',
        'font-medium',
        'leading-5',
        'transition',
        'border-b-2',
        'duration-100',
        'ease-in-out',
        [isActive, 'border-indigo-500', 'border-transparent']
      ])}
    >
      <button
        onClick={() => navigate(navigateTo)}
        className={getClassName([
          'text-gray-600',
          'pt-1',
          'cursor-pointer',
          'hover:text-gray-500',
          'focus:outline-none'
        ])}
      >
        {name}
      </button>
    </div>
  )
}

export const DisplayPage: React.FC<DisplayPageProps> = ({
  children
}: DisplayPageProps) => {
  const { logout } = useAuth0()
  const { pathname } = useLocation()
  const [route] = pathname.split('/').filter(Boolean)

  const { hasLoadedUserData, hasLoadedPermissions, activeClient } =
    useContext(UserContext)

  const navigate = useNavigate()

  const logoutMenuOption = {
    name: 'Logout',
    onClick: () => {
      logout({ returnTo: window.location.origin })
    },
    isActive: route === 'logout'
  }

  const documentsOption = {
    name: 'Documents',
    onClick: () => navigate(`docs`),
    isActive: route === 'docs'
  }

  const paymentsOption = {
    name: 'Payments',
    onClick: () => navigate(`payments`),
    isActive: route === 'payments'
  }

  if (!(hasLoadedUserData && hasLoadedPermissions)) {
    return (
      <AnimatedLogo
        className={'w-1/2 mx-auto mt-40 h-1/2 md:w-1/4 md:h-1/4'}
        fullScreen
        text="Getting documents from the vault..."
      />
    )
  }

  return (
    <StackedLayout
      logo={
        <Link to={`/`}>
          <AnimatedLogo className="w-32 m-auto" />
        </Link>
      }
      navItems={[
        <NavbarButton
          key={'navButton-documents'}
          navigateTo={`/docs/${activeClient ? activeClient?.id : 'noClient'}`}
          name={'Documents'}
          isActive={route === 'docs'}
        />,
        <NavbarButton
          key={'navButton-payments'}
          navigateTo={`payments`}
          name={'Payments'}
          isActive={route === 'payments'}
        />
      ]}
      header={<NavBar menuOptions={[logoutMenuOption]} />}
      userMenu={
        <UserMenu
          menuOptions={[documentsOption, paymentsOption, logoutMenuOption]}
        />
      }
      footer={<Footer />}
      additionalMobileNav={<SettingsButton isMobile />}
      main={<Outlet />}
    />
  )
}
