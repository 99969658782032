import React from 'react'
import { Icon } from '@jdlt-ltd/pongo'

export const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear()

  return (
    <div className="max-w-screen-xl px-4 py-4 mx-auto bg-white md:py-8 lg:py-12 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div className="flex justify-center md:order-2">
        <a
          href="https://www.linkedin.com/company/AndersenUK/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">LinkedIn</span>
          <Icon iconName="linkedin" cssClasses={['w-6', 'h-6']} />
        </a>
        <a
          href="https://www.facebook.com/Andersen-Global-277670479380329/"
          className="ml-6 text-gray-400 hover:text-gray-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Facebook</span>
          <Icon iconName="facebook" cssClasses={['w-6', 'h-6']} />
        </a>
        <a
          href="https://twitter.com/AndersenUK"
          className="ml-6 text-gray-400 hover:text-gray-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Twitter</span>
          <Icon iconName="twitter" cssClasses={['w-6', 'h-6']} />
        </a>
      </div>
      <div className="mt-4 lg:mt-0 md:order-1">
        <p className="text-base leading-6 text-center text-gray-400 truncate whitespace-nowrap">
          &copy; {currentYear} Andersen LLP, All rights reserved.
        </p>
      </div>
    </div>
  )
}
