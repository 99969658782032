const DevConfig = (): {} => {
  return {
    graphql: {
      server: {
        baseUrl: `https://nw10pm79ae.execute-api.eu-west-2.amazonaws.com/dev`
      }
    },
    trackingId: 'UA-166331647-1',
    payment: {
      url: 'https://test.ipg-online.com/connect/gateway/processing',
      urlRedirect:
        'https://ryh736rkcmuxa5dji6wji6hw340qykff.lambda-url.eu-west-2.on.aws',
      storename: '7220541088'
    }
  }
}

export default DevConfig()
