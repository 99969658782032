import { useEffect, useContext, SetStateAction } from 'react'
import { NotificationContext, Error } from '@jdlt-ltd/pongo'

export const useErrorNotificationAndSetState = (
  errorBoolean: boolean,
  errorMessages: Error[],
  setStateFunction?: SetStateAction<any>,
  newState?: any
): void => {
  const { errorNotification } = useContext(NotificationContext)

  useEffect(() => {
    if (errorBoolean) {
      if (setStateFunction) setStateFunction(newState || null)

      if (errorMessages?.length > 0) {
        errorMessages.map(({ message }) =>
          errorNotification({
            title: `Error`,
            message: decodeURIComponent(message)
          })
        )
      }
    }
  }, [
    errorBoolean,
    errorMessages,
    errorNotification,
    setStateFunction,
    newState
  ])
}
