import { ItemQuery, NotificationContext } from '@jdlt-ltd/pongo'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { S3ObjectsData } from '../../services/graphQl'

type DeleteFileType = {
  filesToDelete: {
    [key: string]: {
      sessionId: string
      clientId: string | undefined
      currentFolderPath: string
      name: string
      idToken: string | null
    }
  }
  removeFile: (
    idToken: string,
    sessionId: string,
    clientId: string,
    path: string,
    name: string
  ) => Promise<S3ObjectsData>
  setS3Data: Dispatch<SetStateAction<S3ObjectsData | null>>
  setFilesToDelete: Dispatch<
    SetStateAction<{
      [key: string]: {
        sessionId: string
        clientId: string | undefined
        currentFolderPath: string
        name: string
        idToken: string | null
      }
    }>
  >
}

export const DeleteFile: React.FC<DeleteFileType> = ({
  filesToDelete,
  removeFile,
  setS3Data,
  setFilesToDelete
}: DeleteFileType) => {
  const { errorNotification } = useContext(NotificationContext)

  const deleteFileQueries = Object.keys(filesToDelete).map(
    (deleteFileVarObjKey) => {
      const { idToken, sessionId, clientId, currentFolderPath, name } =
        filesToDelete[deleteFileVarObjKey]

      const removeFromFilesToDelete = () => {
        setFilesToDelete((currentState) => {
          const newState = { ...currentState }
          delete newState[deleteFileVarObjKey]
          return newState
        })
      }

      return (
        <ItemQuery
          key={JSON.stringify(filesToDelete[deleteFileVarObjKey])}
          apiQueryFunc={removeFile}
          variables={[idToken, sessionId, clientId, currentFolderPath, name]}
          onComplete={(data) => {
            const currentFolderContent = data[currentFolderPath]
            const filterdContent = currentFolderContent.filter(
              ({ name: fileName }: any) => fileName !== name
            )
            setS3Data({ ...data, [currentFolderPath]: [...filterdContent] })
            removeFromFilesToDelete()
          }}
          onError={(error) => {
            errorNotification({
              title: `Error`,
              message: decodeURIComponent(error)
            })
            removeFromFilesToDelete()
          }}
        />
      )
    }
  )

  return <>{deleteFileQueries}</>
}
