import React from 'react'
import { IconName, DropdownItemProps, Dropdown } from '@jdlt-ltd/pongo'
import { ClickEventValue, FileOrFolder } from './types'
import bytes from 'bytes'
import { getFormattedDate } from '../utils'

export const getFileOrFolderDropdown = ({
  fileOrFolder: { name, type, size, lastModified },
  handleClickRename,
  handleDelete,
  handleDownload,
  handleClickMove,
  handleView
}: {
  fileOrFolder: FileOrFolder
  handleClickRename: Function
  handleDelete: Function
  handleDownload: Function
  handleClickMove: Function
  handleView?: Function
}): JSX.Element => {
  const folderOptions = [
    {
      content: 'Rename',
      icon: 'rename' as IconName,
      divided: true,
      onClick: (_e: React.MouseEvent, data: ClickEventValue): void =>
        handleClickRename({ ...data, type })
    },
    {
      content: 'Delete',
      icon: 'delete' as IconName,
      onClick: (_e: React.MouseEvent, data: ClickEventValue): void =>
        handleDelete(data)
    }
  ]

  const fileViewer = handleView && {
    content: 'Open',
    icon: 'document' as IconName,
    onClick: (_e: React.MouseEvent, data: ClickEventValue): void =>
      handleView(data)
  }

  const baseFileOptions = [
    {
      content: 'Download',
      icon: 'download' as IconName,
      divided: true,
      onClick: (_e: React.MouseEvent, data: ClickEventValue): void =>
        handleDownload(data)
    },
    {
      content: 'Move',
      icon: 'move' as IconName,
      onClick: (_e: React.MouseEvent, data: ClickEventValue): void =>
        handleClickMove(data)
    },
    ...folderOptions
  ]

  const additionalFileOptions: DropdownItemProps[] = [
    ...(lastModified
      ? [
          {
            content: (
              <div className={'text-gray-400'}>
                Last modified:
                <div className={'font-light'}>
                  {getFormattedDate(lastModified)}
                </div>
              </div>
            ),
            cssClasses: ['sm:hidden'],
            disabled: true
          }
        ]
      : []),
    ...(size
      ? [
          {
            content: (
              <div className={'text-gray-400'}>
                File size:
                <div className={'font-light'}>
                  {bytes(size, { decimalPlaces: 0 })}
                </div>
              </div>
            ),
            cssClasses: ['lg:hidden'],
            disabled: true
          }
        ]
      : [])
  ]

  const options =
    type === 'folder'
      ? folderOptions
      : handleView
      ? [...additionalFileOptions, fileViewer, ...baseFileOptions]
      : [...additionalFileOptions, ...baseFileOptions]

  return (
    <Dropdown
      options={options.map((fileOrFolderOption) => ({
        ...fileOrFolderOption,
        value: name
      }))}
    />
  )
}
