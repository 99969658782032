import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import config from '../config'

export const Auth0ProviderWithHistory = ({
  children
}: React.PropsWithChildren<any>): JSX.Element => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    // Use the router's history module to replace the url
    navigate((appState && appState?.returnTo) || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={config.get().auth0.domain}
      clientId={config.get().auth0.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
