const ProductionConfig = (): {} => {
  return {
    graphql: {
      server: {
        baseUrl: `https://9but2x3i08.execute-api.eu-west-2.amazonaws.com/production`
      }
    },
    trackingId: 'UA-166331647-3',
    payment: {
      url: 'https://www.ipg-online.com/connect/gateway/processing',
      storename: '7250508644',
      urlRedirect:
        'https://ffs7eu2xcta3jcu6iruwtlqqmy0cinpq.lambda-url.eu-west-2.on.aws'
    }
  }
}

export default ProductionConfig()
