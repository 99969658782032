import React from 'react'
import { useLocation } from 'react-router-dom'

import { Button } from '@jdlt-ltd/pongo'
import { useLogRocketIdentify } from '../../utils'
import config from '../../config'

const useQuery = () => new URLSearchParams(useLocation().search)

export const TsAndCs: React.FC = () => {
  useLogRocketIdentify()

  const query = useQuery()

  const loginState = query.get('state')

  const pClass = 'mx-2 mb-2'
  const h2Class = 'mt-4 mb-2'
  const ulClass = 'mx-4 mb-2 list-disc list-inside'

  const {
    webDomain,
    itSupportAddress,
    auth0: { domain: auth0Domain }
  } = config.get()

  return (
    <div className="container px-4 mx-auto my-6 sm:px-6 lg:px-8">
      <div className="overflow-hidden bg-white rounded-lg shadow">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <h1
            className="text-lg font-medium leading-6 text-gray-900"
            id="andersen-uk-client-portal---terms-of-use"
          >
            Andersen UK Client Portal - Terms of use
          </h1>
          <h3
            className="mt-1 text-sm leading-5 text-gray-500"
            id="you-must-read-these-terms-of-use-carefully-before-using-this-site-referred-to-in-these-terms-as-our-portal"
          >
            YOU MUST READ AND ACCEPT THESE TERMS OF USE CAREFULLY BEFORE USING
            THIS SITE (REFERRED TO IN THESE TERMS AS OUR “PORTAL”){' '}
          </h3>
        </div>
        <div className="px-4 py-5 text-xs sm:p-6">
          <h2 className={h2Class} id="who-we-are-and-how-to-contact-us">
            WHO WE ARE AND HOW TO CONTACT US
          </h2>
          <p className={pClass}>
            This Portal is a site operated by, or on behalf of, Andersen LLP.
            Andersen LLP is a limited liability partnership registered in
            England with registered number OC421079. The registered office of
            Andersen LLP is Duane Morris, Citypoint, 16th Floor, One Ropemaker
            Street, London, UK EC2Y 9AW. The trading address of Andersen LLP is
            80 Coleman Street, London EC2R 5BJ.
          </p>
          <p className={pClass}>
            To contact us, please email{' '}
            <a href={`mailto:${itSupportAddress}`}>
              <span className="underline">{itSupportAddress}</span>
            </a>{' '}
            or call us on{' '}
            <a href="tel:+442072425000">
              <span className="underline">+44 20 7242 5000</span>
            </a>
            .
          </p>
          <h2
            className={h2Class}
            id="by-using-our-portal-you-accept-these-terms-of-use"
          >
            BY USING OUR PORTAL YOU ACCEPT THESE TERMS OF USE
          </h2>
          <p className={pClass}>
            By using our Portal, you confirm that you accept these Terms of use
            and that you agree to comply with them. If you do not agree to these
            Terms of use, you are not entitled to use our Portal and must not
            use our Portal.
          </p>
          <p className={pClass}>
            Only an individual whose identity and email address match those of
            an authorised user who has been granted access to the Portal by us
            shall be entitled to access the Portal and such access may be
            withdrawn at any time without any reason being required to be given
            or any prior notice. If you are not such an individual, you must not
            use our Portal.
          </p>
          <p className={pClass}>
            We recommend that you print a copy of these Terms of use for future
            reference.
          </p>
          <h2
            className={h2Class}
            id="there-are-other-terms-that-may-apply-to-you"
          >
            THERE ARE OTHER TERMS THAT MAY APPLY TO YOU
          </h2>
          <p className={pClass}>
            These Terms of use refer to our Acceptable Use Policy, which also
            applies to your use of our Portal. This Acceptable Use Policy
            (included as a schedule at the end of these Terms of use) sets out
            the permitted uses and prohibited uses of our Portal. When using our
            Portal, you must comply with the Acceptable Use Policy.
          </p>
          <h2
            className={h2Class}
            id="we-may-make-changes-to-these-terms-of-use"
          >
            WE MAY MAKE CHANGES TO THESE TERMS OF USE
          </h2>
          <p className={pClass}>
            We may amend these Terms of use from time to time. Every time you
            use our Portal, you should check these Terms of use to ensure you
            understand the terms that apply at that time.
          </p>
          <h2 className={h2Class} id="we-may-make-changes-to-our-portal">
            WE MAY MAKE CHANGES TO OUR PORTAL
          </h2>
          <p className={pClass}>
            We may update and change our Portal from time to time.
          </p>
          <h2 className={h2Class} id="we-may-suspend-or-withdraw-our-portal">
            WE MAY SUSPEND OR WITHDRAW OUR PORTAL
          </h2>
          <p className={pClass}>
            We do not guarantee that our Portal, or any content on it, will
            always be available or be uninterrupted. We may suspend or withdraw
            or restrict the availability of all or any part of our Portal for
            any reason.
          </p>
          <p className={pClass}>
            You are also responsible for ensuring that all persons who access
            our Portal through your internet connection are aware of these Terms
            of use, the Acceptable Use Policy and other applicable terms and
            conditions, and that they comply with them.
          </p>
          <h2 className={h2Class} id="you-must-keep-your-account-details-safe">
            YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE
          </h2>
          <p className={pClass}>
            If you choose, or you are provided with, a user identification code,
            password or any other piece of information as part of our security
            procedures, you must treat such information as confidential. You
            must not disclose it to any other person.
          </p>
          <p className={pClass}>
            Without notice, we have the right to disable any user identification
            code or password, whether chosen by you or allocated by us, at any
            time, if in our reasonable opinion you have failed to comply with
            any of the provisions of these Terms of use.
          </p>
          <p className={pClass}>
            If you know or suspect that anyone other than you knows your user
            identification code or password, you must promptly notify us at{' '}
            <a href={`mailto:${itSupportAddress}`}>
              <span className="underline">{itSupportAddress}</span>
            </a>
            .
          </p>
          <h2 className={h2Class} id="confidentiality">
            CONFIDENTIALITY
          </h2>
          <p className={pClass}>
            The Portal is very likely to contain sensitive information or
            documentation (whether of a financial nature or relating to
            commercial or other relevant matters) and may also include
            information relating to an identified or identifiable living
            individuals. For the purposes of these Terms such data shall be
            taken to mean personal data as defined at Article 4 of the GDPR (EU
            General Data Protection Regulation (EU 2016/679) and reference to
            identifiable living individual shall be taken to have the meaning
            described at 3(3) of the Data Protection Act 2018.
          </p>
          <p className={pClass}>
            All information which is capable of being regarded as confidential
            shall be treated by you on a strictly confidential basis and may not
            be disclosed to any third party unless you have the prior written
            consent to do so from the party who disclosed it. You agree that you
            will not process any personal data contained in any document on the
            Portal otherwise than as permitted under these Terms and in
            accordance with the relevant data protection legislation which
            includes the aforementioned GDPR and Data Protection Act 2018. In
            the event that you become aware that any personal data held on the
            Portal has been compromised, you agree to notify us immediately and
            to assist us and our regulators in relation to any consequential
            investigation.
          </p>
          <p className={pClass}>
            We will process any personal data that we collect about you in
            accordance with our privacy policy which can be found on our website
            at {webDomain}.
          </p>
          <h2 className={h2Class} id="no-warranty">
            NO WARRANTY
          </h2>
          <p className={pClass}>
            No representation or warranty, express or implied, is made or given
            as to the adequacy, accuracy, reliability or completeness of the
            information or documentation on the Portal or as to the
            reasonableness of any assumptions on which any of it is based. You
            agree, on your own behalf and on behalf of any person to whom you
            make known any part of the information or documentation (subject to
            the paragraph above), that except as may be otherwise provided in
            any agreement relating to any proposed transaction to which the
            information and/or documentation relates, neither we nor any persons
            for whom we act or their subsidiary undertakings, respective
            members, partners, directors, employees, advisers or agents or their
            other professional advisers have any liability to any person to whom
            information or documentation is made available, resulting from the
            use of the information or documentation. In making the information
            or documentation available, no obligation is imposed relating to any
            additional information or documentation or to update or correct any
            inaccuracies which may become apparent in any of the information or
            documentation.
          </p>
          <h2 className={h2Class} id="using-the-portal">
            USING THE PORTAL
          </h2>
          <p className={pClass}>Whilst using the Portal, you shall:</p>
          <ul className={ulClass}>
            <li>
              Take all reasonable steps to ensure that none of the information
              or documentation is visible to, or capable of being, overlooked
              by, other persons
            </li>
            <li>
              Not access the Portal from a shared computer, an internet café or
              other place where the public has access
            </li>
            <li>
              Not leave your computer or other communications device through
              which you access the service unattended whilst connected to the
              Portal
            </li>
            <li>
              Ensure that you close your internet browser and log out when you
              have finished using the Portal
            </li>
            <li>
              Not deface, mark, alter, modify, vary (including varying the
              sequence of) damage or destroy in any way any information or
              documentation contained on the Portal
            </li>
            <li>
              Not attempt to download, scan, copy, print, photograph or
              otherwise capture any of the information or documentation
              contained in the Portal, except that you may print or download any
              documentation for which print or download capability (as the case
              may be) has been enabled
            </li>
            <li>
              Not attempt to disable the protection software associated with the
              Portal, and
            </li>
            <li>
              Not share your Portal login details or password with anyone.
            </li>
          </ul>
          <h2
            className={h2Class}
            id="destruction-of-documents-downloaded-from-the-portal"
          >
            DESTRUCTION OF DOCUMENTS DOWNLOADED FROM THE PORTAL
          </h2>
          <p className={pClass}>At our request, you agree to:</p>
          <ul className={ulClass}>
            <li>
              Return all information and documentation downloaded from the
              Portal and any information derived or generated therefrom together
              with any copies thereof (in all cases whether in your possession
              or in the possession of others to whom such information is made
              known in whatever form)
            </li>
            <li>
              Delete all information downloaded from the Portal and any
              information derived or generated therefrom from any computer, word
              processor, tablet, smartphone or other communication device
              through which the website is accessed, or other like device in
              your possession, custody or control or in the possession, custody
              or control of others to whom such information is made known in
              whatever form, and
            </li>
            <li>
              Provide a certificate confirming that the provisions of this
              section have been complied with.
            </li>
          </ul>
          <h2 className={h2Class} id="how-you-may-use-material-on-our-portal">
            HOW YOU MAY USE MATERIAL ON OUR PORTAL
          </h2>
          <p className={pClass}>
            The material published on our Portal is likely to be protected by
            copyright laws and treaties around the world. You must respect all
            such rights.
          </p>
          <p className={pClass}>
            To the extent that print capability has been enabled, you may print
            off one copy, and may download extracts, of any page(s) from our
            Portal for the purpose for which we have provided you with access to
            the Portal. Save in conjunction with this purpose, you may not draw
            the attention of others to content posted on our Portal.
          </p>
          <p className={pClass}>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </p>
          <p className={pClass}>
            If you print off, copy or download any part of our Portal in breach
            of these Terms of use, your right to use our Portal will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </p>
          <h2
            className={h2Class}
            id="do-not-rely-on-information-on-this-portal"
          >
            DO NOT RELY ON INFORMATION ON THIS PORTAL
          </h2>
          <p className={pClass}>
            We make no representations, warranties or guarantees, whether
            express or implied, that the content on our Portal is accurate,
            complete or up to date.
          </p>
          <p className={pClass}>
            Further materials and documentation may be added to the Portal from
            time to time and it is your responsibility to check the Portal for
            additional items or amendments.
          </p>
          <p className={pClass}>
            We reserve the right to withhold any information from the Portal
            from time to time for reasons of confidentiality, commercial
            sensitivity or any other reason at our or our client’s sole
            discretion.
          </p>
          <h2
            className={h2Class}
            id="our-responsibility-for-loss-or-damage-suffered-by-you"
          >
            OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
          </h2>
          <p className={pClass}>
            We do not exclude or limit in any way our liability to you where it
            would be unlawful to do so under the laws of the United Kingdom.
            This includes liability for death or personal injury caused by our
            negligence or the negligence of our employees, agents or
            subcontractors and for fraud or fraudulent misrepresentation.
          </p>
          <p className={pClass}>
            We exclude all implied conditions, warranties, representations or
            other terms that may apply to our Portal or any content on it. We
            will not be liable to you for any loss or damage, whether in
            contract, tort (including negligence), breach of statutory duty, or
            otherwise, even if foreseeable, arising under or in connection with:
          </p>
          <ul className={ulClass}>
            <li>Use of, or inability to use, our Portal, or</li>
            <li>Use of or reliance on any content displayed on our Portal.</li>
          </ul>
          <p className={pClass}>In particular, we will not be liable for:</p>
          <ul className={ulClass}>
            <li>Loss of profits, sales, business, or revenue</li>
            <li>Business interruption</li>
            <li>Loss of anticipated savings</li>
            <li>Loss of business opportunity, goodwill or reputation, or</li>
            <li>Any indirect or consequential loss or damage.</li>
          </ul>
          <h2 className={h2Class} id="uploading-content-to-our-portal">
            UPLOADING CONTENT TO OUR PORTAL
          </h2>
          <p className={pClass}>
            Whenever you make use of a feature that allows you to upload content
            to our Portal, or to make contact with other users of our Portal,
            you must comply with the content standards set out in our Acceptable
            Use Policy at the end of these Terms of use.
          </p>
          <p className={pClass}>
            You warrant that any such contribution does comply with those
            standards, and you will be liable to us and indemnify us for any
            breach of that warranty. This means you will be responsible for any
            loss or damage we suffer as a result of your breach of warranty.
          </p>
          <p className={pClass}>
            We have the right to disclose your identity to any third party who
            is claiming that any content posted or uploaded by you to our Portal
            constitutes a violation of their intellectual property rights, or of
            their right to privacy.
          </p>
          <p className={pClass}>
            We have the right to remove any posting you make on our Portal if,
            in our opinion, your post does not comply with the content standards
            set out in our Acceptable Use Policy.
          </p>
          <p className={pClass}>
            You are solely responsible for securing and backing up your content.
          </p>
          <h2
            className={h2Class}
            id="rights-you-are-giving-us-to-use-material-you-upload"
          >
            RIGHTS YOU ARE GIVING US TO USE MATERIAL YOU UPLOAD
          </h2>
          <p className={pClass}>
            When you upload or post content to our Portal, you grant us and
            other users of the Portal the rights to use that content.
          </p>
          <h2
            className={h2Class}
            id="we-are-not-responsible-for-viruses-and-you-must-not-introduce-them"
          >
            WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM
          </h2>
          <p className={pClass}>
            We do not guarantee that our Portal will be secure or free from bugs
            or viruses.
          </p>
          <p className={pClass}>
            You are responsible for configuring your information technology,
            computer programmes and platform to access our Portal. You should
            use your own virus protection software.
          </p>
          <p className={pClass}>
            You must not misuse our Portal by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our Portal, the server on which our Portal is stored or
            any server, computer or database connected to our Portal. You must
            not attack our Portal via a denial-of-service attack or a
            distributed denial-of service attack. By breaching this provision,
            you would commit a criminal offence under the United Kingdom’s
            Computer Misuse Act 1990. We will report any such breach to the
            relevant law enforcement authorities and we will co-operate with
            those authorities by disclosing your identity to them. In the event
            of such a breach, your right to use our Portal will cease
            immediately.
          </p>
          <h2 className={h2Class} id="linking-to-our-portal">
            LINKING TO OUR PORTAL
          </h2>
          <p className={pClass}>
            You must not establish a link to our Portal in any site nor may our
            Portal be framed on any other site.
          </p>
          <h2
            className={h2Class}
            id="which-countrys-laws-apply-to-any-disputes"
          >
            WHICH COUNTRY&apos;S LAWS APPLY TO ANY DISPUTES?
          </h2>
          <p className={pClass}>
            These Terms of use, the Acceptable Use Policy, their subject matter
            and their formation (and any non-contractual disputes or claims) are
            governed by English law. In the event of a dispute arising out of or
            relating to these Terms of use or the Acceptable Use Policy,
            including any question regarding their existence, validity or
            termination, you and us shall first seek settlement of that dispute
            by mediation in accordance with the London Court of International
            Arbitration’s Mediation Rules of July 2012, which Mediation Rules
            are deemed to be incorporated by reference into this clause.
          </p>
          <p className={pClass}>
            If the dispute is not settled by mediation within 21 days of the
            commencement of the mediation, or such further period as you or we
            may agree in writing, the dispute shall be referred to and finally
            resolved by arbitration under the London Court of International
            Arbitration’s Arbitration Rules of October 2014, which Arbitration
            Rules are hereby deemed to be incorporated by reference into this
            clause. The language to be used in any mediation or arbitration
            shall be English. In any arbitration commenced pursuant to this
            clause, the number of arbitrators shall be one and the seat, or
            legal place, of arbitration shall be London, England.
          </p>
          <p className={pClass}>
            In the event of any dispute as to whether any person has accessed or
            used the Portal, viewed or uploaded any information or documentation
            or performed any other activity within the Portal, the records
            (including, without limitation, any real-time reports) produced or
            maintained by, or made available to us that relate to such access or
            activity shall be deemed conclusive evidence of such access or
            activity.
          </p>
          <h2 className={h2Class} id="severance">
            SEVERANCE
          </h2>
          <p className={pClass}>
            If any provision or part-provision of these Terms of use or the
            Acceptable Use Policy is or becomes invalid, illegal or
            unenforceable, it shall be deemed modified to the minimum extent
            necessary to make it valid, legal and enforceable. If such
            modification is not possible, the relevant provision or
            part-provision shall be deemed deleted. Any modification to or
            deletion of a provision or part-provision under this clause shall
            not affect the validity and enforceability of the rest of these
            Terms of use or the Acceptable Use Policy.
          </p>
          <h2 className={h2Class} id="remedies">
            REMEDIES
          </h2>
          <p className={pClass}>
            Without prejudice to any other rights or remedies which we or our
            client may have, you acknowledge and agree that damages alone are
            unlikely to be an adequate remedy for any breach of the provisions
            of these Terms of use and that accordingly, we and our client may
            (subject to the discretion of the courts) be entitled to the
            remedies of injunction, specific performance or other equitable
            relief for any threatened or actual breach of these Terms of use and
            no proof of special damages shall be necessary to enforce these
            Terms of use.
          </p>
          <p className={pClass}>
            Our rights in respect of a breach of these Terms of use shall not be
            affected by the giving of time or other indulgence to any person nor
            by any other cause except a specific waiver in writing, which may be
            subject to any condition which we in our absolute discretion think
            fit. Such waiver shall not prejudice or affect any remaining rights
            or remedies provided by law or by these Terms of use. Unless
            otherwise expressly stated, any waiver shall be effective only in
            respect of the particular matter and purpose for which it is given.
          </p>
          <h2 className={h2Class} id="our-acceptable-use-policy">
            OUR ACCEPTABLE USE POLICY
          </h2>
          <p className={pClass}>
            This Acceptable Use Policy sets out the terms between you and us
            under which you may access the Portal and supplement the Terms of
            use. Only an individual whose identity and email address match those
            of an authorised user who has been granted access to the Portal by
            us shall be entitled to access the Portal and such access may be
            withdrawn at any time without any reason being required to be given
            or any prior notice.
          </p>
          <p className={pClass}>
            Your use of the Portal means that you accept, and agree to abide by,
            all the policies in this Acceptable Use Policy.
          </p>
          <h2 className={h2Class} id="prohibited-uses">
            PROHIBITED USES
          </h2>
          <p className={pClass}>
            You may use the Portal only for lawful purposes. You may not use the
            Portal:
          </p>
          <ul className={ulClass}>
            <li>
              In any way that breaches any applicable local, national or
              international law or regulation
            </li>
            <li>
              In any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect
            </li>
            <li>
              For the purpose of harming or attempting to harm minors in any way
            </li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with the Content Standards below
            </li>
            <li>
              To transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam)
            </li>
            <li>
              To knowingly transmit any data, send or upload any material that
              contains viruses, trojans, worms, time-bombs, keystroke loggers,
              spyware, adware or any other harmful programs or similar computer
              code designed to adversely affect the operation of any computer
              software or hardware.
            </li>
          </ul>
          <p className={pClass}>You also agree:</p>
          <ul className={ulClass}>
            <li>
              Not to reproduce, duplicate, copy or re-sell any part of the
              Portal in contravention of the provisions of the Terms of use.
            </li>
            <li>
              Not to access without authority, interfere with, damage or
              disrupt:
              <ul className={ulClass}>
                <li>any part of the Portal</li>
                <li>any equipment or network on which the Portal is stored</li>
                <li>any software used in the provision of the Portal, or</li>
                <li>
                  any equipment or network or software owned or used by any
                  third party.
                </li>
              </ul>
            </li>
          </ul>
          <p className={pClass}>
            If you gain access to any section of the Portal and or any
            information or documentation to which you know or suspect you should
            not have access:
          </p>
          <ul className={ulClass}>
            <li>
              You must immediately exit that section and inform us of such
              unauthorised access; and
            </li>
            <li>
              You must not divulge to any person the fact of the unauthorised
              access, save where required to do so by a court order.
            </li>
          </ul>
          <h2 className={h2Class} id="interactive-services">
            INTERACTIVE SERVICES
          </h2>
          <p className={pClass}>
            We may from time to time provide interactive services on the Portal,
            including, without limitation, messaging features, directories and
            shared calendars (interactive services).
          </p>
          <p className={pClass}>
            We will do our best to assess any possible risks for users (and in
            particular, for children) from third parties when they use any
            interactive service provided on the Portal, and we will decide in
            each case whether it is appropriate to use moderation of the
            relevant service (including what kind of moderation to use) in the
            light of those risks. However, we are under no obligation to
            oversee, monitor or moderate any interactive service we provide on
            the Portal, and we expressly exclude our liability for any loss or
            damage arising from the use of any interactive service by a user in
            contravention of our content standards, whether the service is
            moderated or not.
          </p>
          <p className={pClass}>
            The use of any of our interactive services by a minor is subject to
            the consent of their parent or guardian. We advise parents who
            permit their children to use an interactive service that it is
            important that they communicate with their children about their
            safety online, as moderation is not foolproof. Minors who are using
            any interactive service should be made aware of the potential risks
            to them.
          </p>
          <p className={pClass}>
            Where we do moderate an interactive service, we will normally
            provide you with a means of contacting the moderator, should a
            concern or difficulty arise.
          </p>
          <h2 className={h2Class}>CONTENT STANDARDS</h2>
          <p className={pClass}>
            These content standards apply to any and all material which you
            contribute to the Portal (contributions), and to any interactive
            services associated with it.
          </p>
          <p className={pClass}>
            You must comply with the spirit and the letter of the following
            standards. The standards apply to each part of any contribution as
            well as to its whole.
          </p>
          <p className={pClass}>Contributions must:</p>
          <ul className={ulClass}>
            <li>Be accurate (where they state facts)</li>
            <li>Be genuinely held (where they state opinions), and</li>
            <li>
              Comply with and not contravene applicable law in the United
              Kingdom and in any country from which they are posted including,
              without limitation, the GDPR (EU General Data Protection
              Regulation (EU 2016/679)) and the Data Protection Act 2018.
            </li>
          </ul>
          <p className={pClass}>Contributions must not:</p>
          <ul className={ulClass}>
            <li>Contain any material which is defamatory of any person</li>
            <li>
              Contain any material which is obscene, offensive, hateful or
              inflammatory
            </li>
            <li>Promote sexually explicit material</li>
            <li>Promote violence</li>
            <li>
              Promote discrimination based on age, disability, gender
              reassignment, marriage or civil partnership, pregnancy or
              maternity, race, religion or belief, or sex
            </li>
            <li>
              Infringe any copyright, database right or trade mark of any other
              person
            </li>
            <li>Be likely to deceive any person</li>
            <li>
              Be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence
            </li>
            <li>Promote any illegal activity</li>
            <li>
              Be threatening, abuse or invade another’s privacy, or cause
              annoyance, inconvenience or needless anxiety
            </li>
            <li>
              Be likely to harass, upset, embarrass, alarm or annoy any other
              person
            </li>
            <li>
              Be used to impersonate any person, or to misrepresent your
              identity or affiliation with any person
            </li>
            <li>
              Give the impression that they emanate from us, if this is not the
              case, and
            </li>
            <li>
              Advocate, promote or assist any unlawful act such as (by way of
              example only) copyright infringement or computer misuse.
            </li>
          </ul>
          <h2 className={h2Class} id="suspension-and-termination">
            SUSPENSION AND TERMINATION
          </h2>
          <p className={pClass}>
            We will determine, in our discretion, whether there has been a
            breach of this Acceptable Use Policy through your use of the Portal.
            When a breach of this policy has occurred, we may take such action
            as we deem appropriate.
          </p>
          <p className={pClass}>
            Failure to comply with this Acceptable Use Policy constitutes a
            material breach of the Terms of use upon which you are permitted to
            use the Portal, and may result in our taking all or any of the
            following actions:
          </p>
          <ul className={ulClass}>
            <li>
              Immediate, temporary or permanent withdrawal of your right to use
              the Portal
            </li>
            <li>
              Immediate, temporary or permanent removal of any posting or
              material uploaded by you to the Portal
            </li>
            <li>Issue of a warning to you</li>
            <li>
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach
            </li>
            <li>
              Further legal action against you, and/or Disclosure of such
              information to law enforcement authorities as we reasonably feel
              is necessary.
            </li>
          </ul>
          <p className={pClass}>
            We exclude liability for actions taken in response to breaches of
            this Acceptable Use Policy. The responses described in this policy
            are not limited, and we may take any other action we reasonably deem
            appropriate.
          </p>
          <p className={pClass}>Changes to the Acceptable Use Policy</p>
          <p className={pClass}>
            We may revise this Acceptable Use Policy at any time by amending the
            Terms of use. You are expected to check this page from time to time
            to take notice of any changes we make, as they are legally binding
            on you. Some of the provisions contained in this Acceptable Use
            Policy may also be superseded by provisions or notices published.
          </p>
        </div>
        {loginState && (
          <div className="m-5">
            <Button
              content="I accept the terms and conditions"
              color="green"
              size="xl"
              onClick={() => {
                window.location.href = `https://${auth0Domain}/continue?state=${loginState}`
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
