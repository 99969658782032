import React, { useContext } from 'react'
import { getClassName, Icon, Popover } from '@jdlt-ltd/pongo'

import { UserContext } from '../../utils'

import { useLocation, useNavigate } from 'react-router-dom'

export const SettingsButton = ({
  isMobile
}: {
  isMobile?: boolean
}): JSX.Element => {
  const { activeClient, hasSettingsPermissions } = useContext(UserContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [route] = pathname.split('/').filter(Boolean)

  const isSettingsActive = route === 'settings'

  const element = (
    <button
      onClick={() => (isSettingsActive ? navigate(-1) : navigate('settings'))}
      className={getClassName([
        'text-gray-600',
        'pt-1',
        'cursor-pointer',
        'hover:text-gray-500',
        'focus:outline-none'
      ])}
    >
      <Icon iconName="cog" cssClasses={['w-6', 'h-6']} />
    </button>
  )

  return hasSettingsPermissions ? (
    <div
      className={getClassName([
        [!!isMobile, 'mr-5'],
        'inline-flex',
        'items-center',
        'h-full',
        'px-1',
        'pt-1',
        'text-sm',
        'font-medium',
        'leading-5',
        'transition',
        'border-b-2',
        'duration-100',
        'ease-in-out',
        [isSettingsActive, 'border-indigo-500', 'border-transparent']
      ])}
    >
      <Popover
        triggerEvent="mouseOver"
        placement="bottom-end"
        referenceElement={element}
        popperElement={
          <div className="p-1">
            <div className="rounded-lg shadow-lg">
              <div className="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
                <div className="relative z-20 px-2 py-1 text-xs bg-white">
                  {isSettingsActive && activeClient
                    ? 'Exit settings'
                    : isSettingsActive && !activeClient
                    ? `You must be assigned to a client
                     to view Documents`
                    : 'Settings'}
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  ) : (
    <></>
  )
}
