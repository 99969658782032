import { makeSingleGraphQlRequest } from './base'

export const getPaymentHash = async (
  idToken: string,
  formDetails: string
): Promise<any> =>
  await makeSingleGraphQlRequest(GET_PAYMENT_HASH, idToken, {
    formDetails
  })

const GET_PAYMENT_HASH = `mutation x($formDetails: String!) {
  payments {
    getPaymentHash(formDetails: $formDetails){
      hash
    }
  }
}`
