// https://stackoverflow.com/questions/24163889/html5-input-for-money-currency
import React, { FormEvent, InputHTMLAttributes, KeyboardEvent } from 'react'

const allowedCharacters = [
  ',',
  '.',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9'
]

const allowedKeys = [
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Home',
  'End',
  'Ctrl',
  'Tab',
  'Shift',
  'Clear'
]

export const InputCurrency: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  value,
  onChange
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Prevent disallowed characters and allow cut, copy & paste
    if (
      !allowedCharacters.includes(e.key) &&
      !allowedKeys.includes(e.key) &&
      !(['x', 'c', 'v'].includes(e.key) && e.ctrlKey)
    ) {
      e.preventDefault()
    }
  }

  const handleBlur = (e: FormEvent<HTMLInputElement>) => {
    // Convert to UK intl.NumberFormat format
    const input = e.currentTarget
    const value = input.value
    const number = Number(value.replace(/[,]/g, ''))
    const formatted = Intl.NumberFormat('en-GB', {
      maximumFractionDigits: 2
    }).format(number)
    input.value = formatted
  }

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="chargetotal"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Amount
        </label>
        <div className="max-w-xs mt-1 sm:mt-0">
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm">£</span>
            </div>
            <input
              type="text"
              name="chargetotal"
              id="chargetotal"
              className="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
              placeholder="0.00"
              aria-describedby="amount-currency"
              autoComplete="transaction-amount"
              onChange={onChange}
              value={value}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              maxLength={13}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="amount-currency">
                GBP
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
