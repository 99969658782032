import React, { Dispatch } from 'react'
import { makeSingleGraphQlRequest } from './base'
import { getId } from '../../utils'

type S3Object = {
  key: string
  lastModified: Date
  size: number
}

export type S3File = {
  name: string
  lastModified: Date
  size: number
  path?: string
}

export type S3ObjectsData = { [key: string]: S3File[] }

type FileDetail = {
  name: string
  mimeType: string
}

type S3SignedUrl = {
  fileName: string
  originalName: string
  signedUrl: string
}

const objectType = `{
      key
      lastModified
      size
    }`

// client id is the output of the client context selector
// path is the folder the user is in. Use a blank string for root
// name is the name of the file/folder you want to perform an operation on

// all methods (except for the ones returning upload/download URLs) return the full folder and file list for a customer as a flat array

export const listObjects = async (
  idToken: string,
  clientId: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(LIST_OBJECTS_QUERY, idToken, { clientId })
  )

export const getUrlForFileUpload = async (
  idToken: string,
  clientId: string,
  path: string,
  name: string,
  mimeType: string
): Promise<any> =>
  await makeSingleGraphQlRequest(GET_URL_FOR_UPLOAD_QUERY, idToken, {
    clientId,
    path,
    name,
    mimeType
  })

export const getUrlForOverwriteFileUpload = async (
  idToken: string,
  clientId: string,
  path: string,
  name: string,
  mimeType: string
): Promise<any> =>
  makeSingleGraphQlRequest(GET_URL_FOR_OVERWRITE_UPLOAD_QUERY, idToken, {
    clientId,
    path,
    name,
    mimeType
  })

export const getUrlForFileDownload = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  name: string
): Promise<any> =>
  await makeSingleGraphQlRequest(GET_URL_FOR_DOWNLOAD_QUERY, idToken, {
    sessionId,
    clientId,
    path,
    name
  })

export const getUrlToViewFileInline = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  name: string
): Promise<any> =>
  await makeSingleGraphQlRequest(GET_URL_FOR_DOWNLOAD_INLINE_QUERY, idToken, {
    sessionId,
    clientId,
    path,
    name
  })

export const getUrlsForFileUploads = async (
  idToken: string,
  clientId: string,
  path: string,
  fileDetails: FileDetail[]
): Promise<{ [key: string]: string }> => {
  const signedUrls = (await makeSingleGraphQlRequest(
    GET_URLS_FOR_UPLOADS_QUERY,
    idToken,
    { clientId, path, fileDetails }
  )) as S3SignedUrl[]

  return signedUrls.reduce(
    (acc, { originalName, signedUrl }) => ({
      ...acc,
      [originalName]: signedUrl
    }),
    {} as { [key: string]: string }
  )
}

export const getUrlsForOverwriteFileUploads = async (
  idToken: string,
  clientId: string,
  path: string,
  fileDetails: FileDetail[]
): Promise<{ [key: string]: string }> => {
  const signedUrls = (await makeSingleGraphQlRequest(
    GET_URLS_FOR_OVERWRITE_UPLOADS_QUERY,
    idToken,
    { clientId, path, fileDetails }
  )) as S3SignedUrl[]

  return signedUrls.reduce(
    (acc, { originalName, signedUrl }) => ({
      ...acc,
      [originalName]: signedUrl
    }),
    {} as { [key: string]: string }
  )
}

export const createFolder = async (
  idToken: string,
  clientId: string,
  path: string,
  name: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(CREATE_FOLDER_MUTATION, idToken, {
      clientId,
      path: path.trim(),
      name
    })
  )

export const renameFolder = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  oldName: string,
  newName: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(RENAME_FOLDER_MUTATION, idToken, {
      sessionId,
      clientId,
      path,
      oldName,
      newName
    })
  )

export const removeFolder = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  name: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(REMOVE_FOLDER_MUTATION, idToken, {
      sessionId,
      clientId,
      path,
      name
    })
  )

export const renameFile = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  oldName: string,
  newName: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(RENAME_FILE_MUTATION, idToken, {
      sessionId,
      clientId,
      path,
      oldName,
      newName
    })
  )

export const removeFile = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  name: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(REMOVE_FILE_MUTATION, idToken, {
      sessionId,
      clientId,
      path,
      name
    })
  )

export const moveFile = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  oldPath: string,
  name: string,
  newPath: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(MOVE_FILE_MUTATION, idToken, {
      sessionId,
      clientId,
      oldPath,
      name,
      newPath
    })
  )

export const confirmFilesUploaded = async (
  idToken: string,
  clientId: string,
  path: string,
  fileNames: string[],
  sessionId: string
): Promise<S3ObjectsData> =>
  transformObjectsData(
    await makeSingleGraphQlRequest(CONFIRM_FILES_UPLOADED, idToken, {
      clientId,
      path,
      fileNames,
      sessionId
    })
  )

const LIST_OBJECTS_QUERY = `query x($clientId: String!) {
  s3 {
    list(clientId: $clientId) ${objectType}
  }
}`

const GET_URL_FOR_UPLOAD_QUERY = `query x($clientId: String!, $path: String!, $name: String!, $mimeType: String!) {
  s3 {
    getUrlForFileUpload(clientId: $clientId, path: $path, name: $name, mimeType: $mimeType)
  }
}`

const GET_URL_FOR_OVERWRITE_UPLOAD_QUERY = `query x($clientId: String!, $path: String!, $name: String!, $mimeType: String!) {
  s3 {
    getUrlForOverwriteFileUpload(clientId: $clientId, path: $path, name: $name, mimeType: $mimeType)
  }
}`

const GET_URL_FOR_DOWNLOAD_QUERY = `query x($clientId: String!, $path: String!, $name: String!, $sessionId:String!) {
  s3 {
    getUrlForFileDownload(clientId: $clientId, path: $path, name: $name, sessionId: $sessionId)
  }
}`

const GET_URL_FOR_DOWNLOAD_INLINE_QUERY = `query x($clientId: String!, $path: String!, $name: String!, $sessionId:String!) {
  s3 {
    getUrlForFileDownloadInline(clientId: $clientId, path: $path, name: $name, sessionId: $sessionId)
  }
}`

const GET_URLS_FOR_UPLOADS_QUERY = `query x($clientId: String!, $path: String!, $fileDetails: [s3_FileDetail!]!) {
  s3 {
    getUrlsForFileUploads(clientId: $clientId, path: $path, fileDetails: $fileDetails) {
      fileName
      originalName
      signedUrl
    }
  }
}`

const GET_URLS_FOR_OVERWRITE_UPLOADS_QUERY = `query x($clientId: String!, $path: String!, $fileDetails: [s3_FileDetail!]!) {
  s3 {
    getUrlsForOverwriteFileUploads(clientId: $clientId, path: $path, fileDetails: $fileDetails) {
      fileName
      originalName
      signedUrl
    }
  }
}`

const CREATE_FOLDER_MUTATION = `mutation x($clientId: String!, $path: String!, $name: String!) {
  s3 {
    createFolder(clientId: $clientId, path: $path, name: $name) ${objectType}
  }
}`

const RENAME_FOLDER_MUTATION = `mutation x($clientId: String!, $path: String!, $oldName: String!, $newName: String!, $sessionId:String!) {
  s3 {
    renameFolder(clientId: $clientId, path: $path, oldName: $oldName, newName: $newName, sessionId: $sessionId) ${objectType}
  }
}`

const REMOVE_FOLDER_MUTATION = `mutation x($clientId: String!, $path: String!, $name: String!, $sessionId:String!) {
  s3 {
    removeFolder(clientId: $clientId, path: $path, name: $name, sessionId: $sessionId) ${objectType}
  }
}`

const RENAME_FILE_MUTATION = `mutation x($clientId: String!, $path: String!, $oldName: String!, $newName: String!, $sessionId:String!) {
  s3 {
    renameFile(clientId: $clientId, path: $path, oldName: $oldName, newName: $newName, sessionId: $sessionId) ${objectType}
  }
}`

const REMOVE_FILE_MUTATION = `mutation x($clientId: String!, $path: String!, $name: String!, $sessionId:String!) {
  s3 {
    removeFile(clientId: $clientId, path: $path, name: $name, sessionId: $sessionId) ${objectType}
  }
}`

const MOVE_FILE_MUTATION = `mutation x($clientId: String!, $oldPath: String!, $name: String!, $newPath: String!, $sessionId:String!) {
  s3 {
    moveFile(clientId: $clientId, oldPath: $oldPath, name: $name, newPath: $newPath, sessionId: $sessionId) ${objectType}
  }
}`

const CONFIRM_FILES_UPLOADED = `mutation x($clientId: String!, $path: String!, $fileNames: [String!]!, $sessionId: String!) {
  s3 {
    confirmFilesUploaded(clientId: $clientId, path: $path, fileNames: $fileNames, sessionId: $sessionId) ${objectType}
  }
}`

const transformObjectsData = (objects: S3Object[]) =>
  objects.reduce((acc, object) => {
    const keyParts = object.key.split(`/`)
    const lastPart = keyParts.pop()

    keyParts.shift()
    const path = keyParts.join(`/`)

    if (!acc[path]) acc[path] = []

    if (lastPart)
      acc[path].push({
        name: lastPart,
        lastModified: new Date(object.lastModified),
        size: object.size
      } as S3File)

    return acc
  }, {} as S3ObjectsData)

export const getObjectsForPath = (
  objectsData: S3ObjectsData,
  path: string
): { files: S3File[]; folders: string[] } => {
  const allFolders = Object.keys(objectsData)
  const childFolders = path
    ? allFolders
        .filter((localPath) => localPath.startsWith(`${path}/`))
        .map((localPath) => localPath.split(`${path}/`).join(``))
    : allFolders.filter((localPath) => localPath)
  const directChildFolders = childFolders.filter(
    (localPath) => localPath.split(`/`).length < 2
  )

  // TODO: this above code only finds folders that have been explicitly created, not those where a parent folder is assumed because a child has been created. Do we want to change this?

  return {
    files: objectsData[path].map((file) => ({ ...file, path })),
    folders: directChildFolders
  }
}

const uploadFile = async (
  file: File,
  signedUrl: string,
  progressFunc?: (fileName: string, progress: number, fileId: string) => void
) =>
  new Promise<File>((resolve, reject) => {
    const fileId = getId()
    const xhr = new XMLHttpRequest()
    xhr.open(`PUT`, signedUrl, true)
    xhr.setRequestHeader(`Content-Type`, file.type)

    xhr.onload = () => {
      console.log(`${file.name} status ${xhr.status}`)
      resolve(file)
    }

    xhr.onerror = () => {
      console.log(` xhr.onerror ${file.name} status ${xhr.status}`)
      reject()
    }

    xhr.upload.onprogress = (event) => {
      if (progressFunc)
        progressFunc(file.name, event.loaded / event.total, fileId)
    }

    xhr.send(file)
  })

const getUrlsForUploads = async ({
  idToken,
  clientId,
  path,
  files,
  isOverwrite = false
}: {
  idToken: string
  clientId: string
  path: string
  files: {
    name: string
    mimeType: string
  }[]
  isOverwrite?: boolean
}) => {
  return isOverwrite
    ? await getUrlsForOverwriteFileUploads(idToken, clientId, path, files)
    : await getUrlsForFileUploads(idToken, clientId, path, files)
}

export const uploadFiles = async (
  idToken: string,
  sessionId: string,
  clientId: string,
  path: string,
  files: File[],
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>,
  progressFunc: (fileName: string, progress: number, fileId: string) => void,
  isOverwrite?: boolean
): Promise<File[]> => {
  const filesArray = Array.from(files)

  const signedUrls = await getUrlsForUploads({
    idToken,
    clientId,
    path,
    files: filesArray.map(({ name, type: mimeType }) => ({
      name,
      mimeType
    })),
    isOverwrite
  })

  const uploads = await filesArray.map(async (file) => {
    const fileUploaded = await uploadFile(
      file,
      signedUrls[file.name],
      progressFunc
    )

    setS3Data((currentS3Data) => ({
      ...currentS3Data,
      [path]: isOverwrite
        ? (currentS3Data as S3ObjectsData)[path].map((file) =>
            file.name === fileUploaded.name
              ? {
                  ...file,
                  lastModified: new Date(fileUploaded.lastModified),
                  size: fileUploaded.size
                }
              : file
          )
        : [
            {
              name: fileUploaded.name,
              lastModified: new Date(fileUploaded.lastModified),
              size: fileUploaded.size
            },
            ...(currentS3Data as S3ObjectsData)[path]
          ]
    }))

    return fileUploaded
  })

  const uploadedFiles = await Promise.all(uploads)

  confirmFilesUploaded(
    idToken as string,
    clientId,
    path,
    uploadedFiles.map(({ name }) => name),
    sessionId
  )

  return uploadedFiles
}
