import { v4 as uuidv4 } from 'uuid'

export const getJdltCookie = (): string => {
  const existingJdltCookie = sessionStorage.getItem('_jdlt')

  if (!existingJdltCookie) {
    const uuid = uuidv4()
    sessionStorage.setItem('_jdlt', uuid)
    return uuid
  } else {
    return existingJdltCookie
  }
}
