import { ItemQuery, NotificationContext } from '@jdlt-ltd/pongo'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { S3ObjectsData } from '../../services/graphQl'

type DeleteFolderType = {
  foldersToDelete: {
    [key: string]: {
      sessionId: string
      clientId: string | undefined
      currentFolderPath: string
      name: string
      idToken: string | null
    }
  }
  removeFolder: (
    idToken: string,
    sessionId: string,
    clientId: string,
    path: string,
    name: string
  ) => Promise<S3ObjectsData>
  setS3Data: Dispatch<SetStateAction<S3ObjectsData | null>>
  setFoldersToDelete: Dispatch<
    SetStateAction<{
      [key: string]: {
        sessionId: string
        clientId: string | undefined
        currentFolderPath: string
        name: string
        idToken: string | null
      }
    }>
  >
}

export const DeleteFolder: React.FC<DeleteFolderType> = ({
  foldersToDelete,
  removeFolder,
  setS3Data,
  setFoldersToDelete
}: DeleteFolderType) => {
  const { errorNotification } = useContext(NotificationContext)

  const deleteFolderQueries = Object.keys(foldersToDelete).map(
    (deleteRequestInfo) => {
      const { idToken, sessionId, clientId, currentFolderPath, name } =
        foldersToDelete[deleteRequestInfo]

      const removeFromFoldersToDelete = () => {
        setFoldersToDelete((currentState) => {
          const newState = { ...currentState }
          delete newState[deleteRequestInfo]
          return newState
        })
      }

      return (
        <ItemQuery
          key={JSON.stringify(foldersToDelete[deleteRequestInfo])}
          apiQueryFunc={removeFolder}
          variables={[idToken, sessionId, clientId, currentFolderPath, name]}
          onComplete={(data) => {
            setS3Data(data)
            removeFromFoldersToDelete()
          }}
          onError={(error) => {
            errorNotification({
              title: `Error`,
              message: decodeURIComponent(error)
            })
            removeFromFoldersToDelete()
          }}
        />
      )
    }
  )

  return <>{deleteFolderQueries}</>
}
