import React, { useState, useEffect, Dispatch, FC } from 'react'
import {
  MoveFile as MoveFileComponent,
  FileOrFolderRowProps
} from '@jdlt-ltd/pongo'
import { S3ObjectsData } from '../../services/graphQl/s3'
import {
  getParentFolderPathFromPath,
  useMoveFile,
  getObjectsForPathAndFormatData
} from '../../utils'

type MoveFileProps = {
  moveFilePath: string
  setMoveFileModalOpen: Dispatch<React.SetStateAction<boolean>>
  createNewFolder: Function
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>
}

export const MoveFile: FC<MoveFileProps> = ({
  moveFilePath,
  setMoveFileModalOpen,
  createNewFolder,
  s3Data,
  setS3Data
}: MoveFileProps) => {
  const { startMoveFile, isMovingFileOrFolder } = useMoveFile({
    s3Data,
    setS3Data
  })

  // State

  const moveFileParentFolderPath = getParentFolderPathFromPath(moveFilePath)

  const [currentFolderPathInMove, setCurrentFolderPathInMove] =
    useState<string>(moveFileParentFolderPath)

  const [destinationFolderPath, setDestinationFolderPath] = useState<string>(
    currentFolderPathInMove
  )

  const [currentFolderDataInMove, setCurrentFolderDataInMove] = useState<
    FileOrFolderRowProps[]
  >([])

  const [isCreateNewFolder, setIsCreateNewFolder] = useState<boolean>(false)

  // Data Functions

  const moveFileAndSetNewState = async (): Promise<void> => {
    const result = await startMoveFile(moveFilePath, destinationFolderPath)
    if (result === 'success') setMoveFileModalOpen(false)
  }

  // UseEffects

  // Sets the current folder data whenever the S3Data or currentFolderPathInMove changes
  useEffect(() => {
    if (s3Data) {
      const { folderData, fileData } = getObjectsForPathAndFormatData(
        s3Data as S3ObjectsData,
        currentFolderPathInMove
      )
      setCurrentFolderDataInMove([...folderData, ...fileData])
    }
  }, [s3Data, currentFolderPathInMove])

  // Sets the destinationFolderPath to the currentFolderPathInMove whenever the currentFolderPathInMove
  useEffect(() => {
    setDestinationFolderPath(currentFolderPathInMove)
  }, [currentFolderPathInMove])

  return (
    <>
      <MoveFileComponent
        isMovingFiles={isMovingFileOrFolder}
        moveFilePathList={[moveFilePath]}
        currentFolderPath={currentFolderPathInMove}
        currentFolderData={currentFolderDataInMove}
        destinationFolderPath={destinationFolderPath}
        isCreateNewFolder={isCreateNewFolder}
        handleIsCreateNewFolder={(state) => setIsCreateNewFolder(state)}
        handleDestinationFolderPath={(folderPath) =>
          setDestinationFolderPath(folderPath)
        }
        handleCurrentFolderPath={(folderPath) =>
          setCurrentFolderPathInMove(folderPath)
        }
        handleMoveFile={moveFileAndSetNewState}
        handleCreateNewFolder={(newFolderName) =>
          createNewFolder(
            currentFolderDataInMove,
            currentFolderPathInMove,
            newFolderName,
            setIsCreateNewFolder
          )
        }
      />
    </>
  )
}
