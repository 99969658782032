import { makeSingleGraphQlRequest } from './base'

const usersSnippet = `
users {
  id
  emailAddress
  name
  picture
  emailVerified
  createdAt
  lastLogin
  blocked
  identities {
    id
    connection
    provider
  } 
  termsAccepted
  termsAcceptedTimestamp
  permissions
  internal 
}`

const LIST_CLIENTS_QUERY = `{
  client {
    listClientDataForCurrentUser {
      id
      name
    }
  }
}`

const LIST_ALL_CLIENTS_QUERY = `{
  client {
    listAll {
      id
      name
      slug
      active
      ${usersSnippet}
    }
  }
}`

const LIST_ROLE_QUERY = `{
  role {
    listAll {
      id name permissions description ${usersSnippet}
    }
  }
}`

const LIST_PERMISSIONS_QUERY = `{
  permission {
    listAll {
      slug
      name
      description
    }
  }
}`

const LIST_USERS_QUERY = `{
  user {
    listAllUsers {
      id
  emailAddress
  name
  picture
  emailVerified
  createdAt
  lastLogin
  blocked
  identities {
    id
    connection
    provider
  } 
  internal
  termsAccepted
  termsAcceptedTimestamp
    }
  }
}`

const objectType = `{
  id,
  slug,
  name,
  ${usersSnippet}
}`

const CREATE_CLIENT = `mutation x($name: String!) {
  client {
    add(name:$name) ${objectType}
    }
  }`

const EDIT_CLIENT_QUERY = `
  mutation x($id: String!, $roleDetails: client_ClientDetails!) {
  client {
    edit(id: $id, roleDetails: $roleDetails) {
      id
      name
      slug
      active
      ${usersSnippet}
    }
  }
}
`
const ASSIGN_USER_TO_CLIENT = `mutation x($userIds: [String!]!, $clientId: String!){
  client { assignUser(userIds: $userIds, id: $clientId) {
    id name ${usersSnippet}
  }}
}`

const UNASSIGN_USER_FROM_CLIENT = `mutation x($userIds: [String!]!, $clientId: String!){
  client { unassignUser(userIds: $userIds, id: $clientId) {
    id name ${usersSnippet}
  }}
}`

const ADD_USER = `mutation x($emailAddress: String!, $name: String!){
  user {
    addUser(emailAddress: $emailAddress, name: $name) {
      id
  emailAddress
  name
  picture
  emailVerified
  createdAt
  lastLogin
  blocked
  identities {
    id
    connection
    provider
  } 
  termsAccepted
  termsAcceptedTimestamp
    }
  }
}`

const EDIT_USER = `mutation x($id: String!, $userDetailsToUpdate: user_EditUser!) {
  user {
    editUser(id: $id, userDetailsToUpdate: $userDetailsToUpdate) {
      id
  emailAddress
  name
  picture
  emailVerified
  createdAt
  lastLogin
  blocked
  identities {
    id
    connection
    provider
  } 
  termsAccepted
  termsAcceptedTimestamp
    }
  }
}
`

const GET_PERMISSIONS = `{
  permission {
    getForCurrentUser
  }
}`

const ASSIGN_USERS_TO_ROLE = `mutation x($id: String!, $userIds: [String!]!) {
  role {
    assignUser (userIds: $userIds, id: $id) {      
      id 
      name 
      description 
      permissions
      users {
        id
        name
      }
    }
  }
}`

const UNASSIGN_USERS_FROM_ROLE = `mutation x($id: String!, $userIds: [String!]!) {
  role {
    unassignUser (userIds: $userIds, id: $id) {      
      id 
      name 
      description 
      permissions
      users {
        id
        name
      }
    }
  }
}`

export const getPermissionsForCurrentUser = async (
  idToken: string
): Promise<any> => makeSingleGraphQlRequest(GET_PERMISSIONS, idToken)

export const listAllRoles = async (idToken: string): Promise<any> =>
  makeSingleGraphQlRequest(LIST_ROLE_QUERY, idToken)

export const listClients = async (idToken: string): Promise<any> =>
  makeSingleGraphQlRequest(LIST_CLIENTS_QUERY, idToken)

export const listUsers = async (idToken: string): Promise<any> =>
  makeSingleGraphQlRequest(LIST_USERS_QUERY, idToken)

export const listPermissions = async (idToken: string): Promise<any> =>
  makeSingleGraphQlRequest(LIST_PERMISSIONS_QUERY, idToken)

export const listAllClients = async (idToken: string): Promise<any> =>
  makeSingleGraphQlRequest(LIST_ALL_CLIENTS_QUERY, idToken)

export const createClient = async (
  idToken: string,
  name: string
): Promise<any> => makeSingleGraphQlRequest(CREATE_CLIENT, idToken, { name })

export const editClient = async (
  idToken: string,
  id: string,
  roleDetails: { name: string; active: boolean }
): Promise<any> =>
  makeSingleGraphQlRequest(EDIT_CLIENT_QUERY, idToken, {
    id,
    roleDetails: { name: roleDetails.name, active: roleDetails.active }
  })

export const assignUserToClient = async (
  idToken: string,
  userIds: string[],
  clientId: string
): Promise<any> =>
  makeSingleGraphQlRequest(ASSIGN_USER_TO_CLIENT, idToken, {
    userIds,
    clientId
  })

export const unassignUserFromClient = async (
  idToken: string,
  userIds: string[],
  clientId: string
): Promise<any> =>
  makeSingleGraphQlRequest(UNASSIGN_USER_FROM_CLIENT, idToken, {
    userIds,
    clientId
  })

export const addAuth0User = async (
  idToken: string,
  emailAddress: string,
  name: string
): Promise<any> =>
  makeSingleGraphQlRequest(ADD_USER, idToken, { emailAddress, name })

export const editUser = async (
  idToken: string,
  id: string,
  userDetailsToUpdate: { blocked: boolean }
): Promise<any> =>
  makeSingleGraphQlRequest(EDIT_USER, idToken, {
    id,
    userDetailsToUpdate: { blocked: userDetailsToUpdate.blocked }
  })

export const assignUsersToRole = async (
  idToken: string,
  userIds: string[],
  id: string
): Promise<any> =>
  makeSingleGraphQlRequest(ASSIGN_USERS_TO_ROLE, idToken, {
    id,
    userIds
  })

export const unassignUsersFromRole = async (
  idToken: string,
  userIds: string[],
  id: string
): Promise<any> =>
  makeSingleGraphQlRequest(UNASSIGN_USERS_FROM_ROLE, idToken, {
    id,
    userIds
  })
