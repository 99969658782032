import React from 'react'
import { Link } from 'react-router-dom'
import { IconName, Breadcrumb } from '@jdlt-ltd/pongo'
import { encodeUrlPath } from '../utils'

type BreadcrumbProps = {
  currentFolderPath: string
  clientId: string
  activeClientName?: string
}

export const Breadcrumbs = ({
  currentFolderPath,
  clientId,
  activeClientName
}: BreadcrumbProps): JSX.Element => {
  const arrayOfS3ObjectsInPath = currentFolderPath
    .split('/')
    .filter((objectPath: string) => objectPath !== '')

  const homeBreadcrumb = [
    {
      children: arrayOfS3ObjectsInPath.some(() => true) ? (
        <Link key={'home'} to={`/docs/${clientId}`}>
          {activeClientName || ``}
        </Link>
      ) : (
        activeClientName || ``
      ),
      icon: 'folders' as IconName,
      isNotClickable: !currentFolderPath
    }
  ]
  const breadcrumbs = arrayOfS3ObjectsInPath.map(
    (object: string, index: number) => {
      const isLastCrumb = index === arrayOfS3ObjectsInPath.length - 1
      const bcPath = isLastCrumb
        ? arrayOfS3ObjectsInPath
            .slice(0, arrayOfS3ObjectsInPath.indexOf(object))
            .join(`/`)
        : arrayOfS3ObjectsInPath
            .slice(0, arrayOfS3ObjectsInPath.indexOf(object) + 1)
            .join(`/`)

      const url = encodeUrlPath(`/docs/${clientId}/${bcPath}`)
      const link = (
        <Link key={index} to={url} className="truncate">
          {object}
        </Link>
      )

      return {
        children: isLastCrumb ? object : link,
        backlinkChildren: (
          <Link key={index} to={url}>
            {object}
          </Link>
        ),
        isNotClickable: isLastCrumb
      }
    }
  )

  return (
    <Breadcrumb
      items={[...homeBreadcrumb, ...breadcrumbs]}
      cssClasses={['container']}
    />
  )
}
