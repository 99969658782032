import React, { KeyboardEvent, useContext } from 'react'
import { format } from 'date-fns'
import { useLogRocketIdentify, UserContext } from '../../utils'
import config from '../../config'
import { InputCurrency } from './InputCurrency'
import { getPaymentHash } from '../../services/graphQl'
import { Outlet } from 'react-router'
import { useParams } from 'react-router'

export type UserFormData = {
  chargetotal?: string
  invoicenumber?: string
}

export type TylData = {
  txntype?: string
  timezone?: string
  txndatetime?: string
  hash_algorithm?: string
  hash?: string
  storename?: any
  mode?: string
  currency?: string
  paymentMethod?: string
  responseSuccessURL?: string
  responseFailURL?: string
} & UserFormData

const { payment } = config.get()

const allowedCharacters = [
  'I',
  'i',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9'
]

const allowedKeys = [
  'ArrowLeft',
  'ArrowRight',
  'Backspace',
  'Delete',
  'Home',
  'End',
  'Ctrl',
  'Tab',
  'Shift',
  'Clear'
]

const getTylFormattedDateTime = () => {
  return format(new Date(), 'yyyy:MM:dd-HH:mm:ss')
}

const tylData: TylData = {
  txntype: 'sale',
  timezone: 'Europe/London',
  hash_algorithm: 'SHA256',
  storename: payment.storename,
  mode: 'payonly',
  currency: '826',
  paymentMethod: 'M'
}

const getHash = async ({
  storename,
  txndatetime,
  chargetotal,
  currency,
  idToken
}: TylData & { idToken: string | null }) => {
  const formDetails = `${storename}${txndatetime}${chargetotal}${currency}`
  const { hash } = await getPaymentHash(idToken || '', formDetails)
  return hash
}

const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  // Prevent disallowed characters and allow cut, copy & paste
  if (
    !allowedCharacters.includes(e.key) &&
    !allowedKeys.includes(e.key) &&
    !(['x', 'c', 'v'].includes(e.key) && e.ctrlKey)
  ) {
    e.preventDefault()
  }
}

const submitForm = (data: FormData, url: string) => {
  const form = document.createElement('form')

  form.setAttribute('method', 'post')
  form.setAttribute('action', url)

  const newInputs = Array.from(data.entries()).map(([key, value]) => {
    const newInput = document.createElement('input')
    newInput.setAttribute('type', 'hidden')
    newInput.setAttribute('name', key)
    newInput.setAttribute('value', value as string)
    return newInput
  })

  newInputs.forEach((input) => form.appendChild(input))

  document.body.appendChild(form)

  form.submit()
}

export const Payments = () => {
  useLogRocketIdentify()
  const { paymentId } = useParams()
  const { idToken } = useContext(UserContext)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const form = e.currentTarget
    const formData = new FormData(form)

    const chargetotal = parseFloat(
      (formData.get('chargetotal') as string)?.replace(/,/g, '')
    )
      .toFixed(2)
      .toString()

    const currentUrl = window.location.href

    const redirectUrl = `${payment.urlRedirect}?origin=${currentUrl}`

    formData.set('chargetotal', chargetotal)
    formData.set('responseSuccessURL', redirectUrl)
    formData.set('responseFailURL', redirectUrl)

    const txndatetime = getTylFormattedDateTime()

    const newTylData: TylData = {
      ...tylData,
      txndatetime,
      hash: await getHash({ ...tylData, chargetotal, idToken, txndatetime })
    }

    Object.entries(newTylData).forEach(([key, value]) => {
      formData.append(key, value)
    })

    submitForm(formData, payment.url)
  }

  if (paymentId) return <Outlet />

  return (
    <>
      <div className="px-4 pb-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
            <div className="w-full mx-4 mt-2">
              <h1 className="text-2xl font-semibold text-gray-900">
                Make a payment
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 mx-auto my-6 sm:px-6 lg:px-8">
        <form className="space-y-6 sm:space-y-5" onSubmit={handleSubmit}>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
            <label
              htmlFor="invoicenumber"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Invoice number
            </label>
            <div className="mt-1 sm:mt-0">
              <input
                type="text"
                name="invoicenumber"
                placeholder="I012345"
                pattern="^[I][0-9]{6}"
                id="invoicenumber"
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xxs sm:text-sm"
                maxLength={7}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <InputCurrency />
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
