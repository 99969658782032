import bytes from 'bytes'
import format from 'date-fns/format'

export const getFormattedDate = (date: string): string =>
  format(new Date(date), `do MMMM yyyy, HH:mm`)

export const getFormattedFileSize = (size: number): string =>
  bytes(size, { decimalPlaces: 0 })

export const containsValidS3FileChars = (string: string): boolean =>
  !!string.match(/^((?![~/\\%]).){0,}$/i)

// disallow ~ as not captured by URI encode
// disallow / as used as folder separator
// disallow % as risky with URI decode as indicates special character

export const containsValidS3FolderChars = (string: string): boolean =>
  !!string.match(/^((?![~./\\%]).){0,}$/i)

// disallow . as routing expects anything with period to be a file

export const getId = (): string =>
  Math.floor(Math.random() * 1000000000).toString()
//safe characters for S3 object keys: https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html

export const encodeUrlPath = (path: string): string => {
  return path
    .split('/')
    .map((directory) => encodeURIComponent(directory))
    .join('/')
}

export const sortEntityAlphabetically = (
  a: { name: string },
  b: { name: string }
): number => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)

export const capitalizeWord = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
