const DefaultConfig = () => {
  return {
    name: `Andersen Portal`,
    webDomain: `uk.andersen.com`,
    itSupportAddress: `ITSupport@uk.andersen.com`,
    auth0: {
      clientId: `AWgPFmDpsA10aXfwAzYDM82DHUL2drON`,
      domain: `auth.uk.andersen.com`,
      audience: `https://andersenuk.eu.auth0.com/api/v2/`
    },
    graphql: {
      server: {
        url: `graphql`
      }
    }
  }
}

export default DefaultConfig()
